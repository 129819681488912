@import '../../../../../../css/styles/configs';

.gt-button {
  .MuiButton-root {
    color: $white;
    padding: 6px 16px;
    font-size: 13px;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: $font-regular;
    border-radius: 2px;

    &.MuiButton-contained {
      background-color: $bg-blue-default;

      &.Mui-disabled {
        background-color: rgba(0, 0, 0, 0.26);
      }
    }

    &.MuiButton-containedSizeSmall {
      padding: 4px 10px;
      font-size: 11px;
    }

    &:hover {
      background-color: $bg-blue-dark;
    }

    &.MuiButton-outlined {
      color: $text-blue;

      &:hover {
        background-color: $white;
        border: 1px solid $bg-blue-dark;
      }
    }
  }
}