@import '../../../../../css/styles/configs';

@mixin items-list ($color) {
  li {
    a {
      padding: 9px 10px 9px 0px;
      display: block;
      font-size: 14px;
      border-bottom: 1px solid $color;
      line-height: 26px;
    }
  }
}

.main-category-list {
  position: relative;
  width: 270px;
  cursor: pointer;

  a.cat-label {
    display: block;
    text-align: left;
    min-width: 270px;
    color: $white;
    background: #0a95da;
    padding: 10px 15px;
    font-size: 15px;
    text-decoration: none;
  }

  .categs {
    position: absolute;
    top: 45px;
    width: 270px;
    z-index: 100;
  }

  .cat-list {
    width: 270px;
    background-color: $white;

    ul {
      margin: 0px;
      padding: 10px 0;
      display: inline-block;
      width: 100%;
      border: 1px solid $gray-medium;
      border-top: 0px;
      padding-bottom: 9px;

      li {
        position: relative;
        display: block;

        a {
          padding: 0px 20px;
          font-size: 14px;
          letter-spacing: 0px;
          width: 100%;
          line-height: 40px;
          color: $text;
          display: block;
          text-decoration: none;
          background: $white;

          span {
            font-size: 10px;
            color: $bg-blue-default;
          }
        }

        &:hover {
          a.menu-label {
            background-color: $bg-gray-light;
          }
          .popup-wrapper {
            visibility: visible;
            left: 99.99%;
            opacity: 1;
          }
        }

        .popup-wrapper {
          position: absolute;
          left: 110%;
          top: 0;
          @include transition(all 0.3s ease-out);
          z-index: 100;
          visibility: hidden;
          opacity: 0;

          .popup-submenu {
            background: $white;
            border: 1px solid $gray-medium;
            display: block;
            text-align: left;
            z-index: 3000;
            padding: 10px 20px;
            font-size: 13px;

            ul {
              border: none;
              width: 200px;
              padding: 0;

              li {
                padding-left: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &:hover {

                  .submenu-arrow {
                    width: 10px;
                  }
                  a {
                    color: $bg-blue-default;
                  }
                }

                .submenu-arrow {
                  font-size: 8px;
                  width: 0px;
                  @include transition(all 0.3s ease-out);
                  color: $bg-blue-default;
                }

                a {
                  line-height: 20px;
                  margin: 8px 0;
                  padding: 0 10px 0 5px;
                  color: $text;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mobile-menu {
  width: 80vw;
  min-width: 140px;
  max-width: 440px;

  &-topbar {
    height: 70px;
    min-height: 70px;
    border-bottom: 1px solid $gray-medium;
    padding: 0 20px;
    @include flex-row-between;

    &__title {
      font-size: 15px;
      cursor: pointer;
      &:hover {
        color: $text-blue;
      }
    }
    .gt-icon {
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: $text-blue;
      }
    }
  }

  .gt-tabview {
    .MuiTabs-root {
      .MuiTab-root {
        flex: 1;
        font-family: $font-regular;
      }
      .MuiTabs-indicator {
        background-color: $bg-blue-default;
      }
    }

    .tab-content {
      padding: 15px;
      font-family: $font-regular;

      &.main-menu {
        ul.nav {
          @include items-list ($gray-medium);
        }
      }

      &.categories-menu {
        .gt-accordion {
          .MuiPaper-root {
            &.MuiExpansionPanel-root.MuiExpansionPanel-rounded:first-child {
              border-radius: 0;
            }
            &.MuiExpansionPanel-root.MuiPaper-elevation1 {
              box-shadow: none;
            }
            &.MuiExpansionPanel-root.Mui-expanded {
              margin: 0;

              &:before {
                opacity: 1;
              }
            }
            
            .MuiExpansionPanelSummary-root {
              padding: 0px;
              min-height: 40px;

              .MuiExpansionPanelSummary-content {
                margin: 0;
                padding: 9px 10px 9px 0px;

                .MuiTypography-body1 {
                  font-family: $font-regular;
                  font-size: 14px;
                  line-height: 26px;
                }
              }
              .MuiIconButton-root {
                padding: 1px 12px 0;
              }
            }

            .MuiCollapse-container {
              .MuiExpansionPanelDetails-root {
                padding: 0;

                ul.nav.submenu {
                  width: 100%;
                  padding: 9px 24px 9px;
                  background-color: $gray-light;

                  @include items-list ($border-blue);

                  li {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    border-bottom: 1px solid $gray-dark;

                    &:hover {
                      .submenu-arrow {
                        width: 10px;
                      }
                      a {
                        color: $bg-blue-default;
                      }
                    }

                    span.submenu-arrow {
                      font-size: 8px;
                      width: 0px;
                      @include transition(all 0.3s ease-out);
                      color: $bg-blue-default;
                    }

                    a {
                      background-color: $gray-light;
                      color: $text;

                      span {
                        font-size: 10px;
                        color: $bg-blue-default;
                      }
                    }

                    &:last-child, a {
                      border: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mobile-menu {
    &-topbar {
      height: 61px;
      min-height: 61px;
    }
    .MuiTab-wrapper {
      font-size: 13px;
    }
    .gt-tabview {
      .tab-content.main-menu ul.nav li a {
        font-size: 13px;
      }
      .tab-content.categories-menu .gt-accordion .MuiPaper-root {
        .MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content .MuiTypography-body1,
        .MuiCollapse-container .MuiExpansionPanelDetails-root ul.nav.submenu li a {
          font-size: 13px;
        }
      }
    }
  }
}