@import '../../../../../../css/styles/configs';

// INPUT FIELD
.gt-input {
  &.outlined {
    .MuiTextField-root {
      width: 100%;

      .MuiInputLabel-outlined {
        font-family: $font-regular;
        font-size: 14px;
        transform: translate(14px, 12px) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(14px, -6px) scale(0.75);
        }
      }

      .MuiOutlinedInput-root {
        background-color: $white;

        .MuiOutlinedInput-input {
          padding: 10px;
          font-family: $font-regular;
          font-size: 14px;
          color: $text;
        }

        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: $text-blue;
          }
        }

        &.Mui-disabled {
          .MuiOutlinedInput-notchedOutline,
          &:hover .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.26);
          }
        }

        &:hover .MuiOutlinedInput-notchedOutline {
          border-color: $text-blue;
          border-width: 1px;
        }
      }
    }
  }
}

// SELECT FIELD
.gt-select {
  &.naked {
    .MuiInput-underline {
      
      &:before,
      &:hover:before,
      &:after {
        border-bottom: 0;
      }

      .MuiSelect-select {
        font-family: $font-regular;
        font-size: 13px;
        color: $text;

        &:focus {
          background: none;
        }
      }
    }
  }

  &.outlined {
    .MuiFormControl-root.MuiTextField-root {
      width: 100%;

      .MuiInputLabel-outlined {
        font-family: $font-regular;
        font-size: 14px;
        transform: translate(14px, 12px) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(14px, -6px) scale(0.75);
        }
      }

      .MuiOutlinedInput-root {
        background-color: $white;

        .MuiSelect-outlined {
          padding: 10px;
          padding-right: 32px;
          font-family: $font-regular;
          font-size: 13px;
          color: $text;
        }

        &:hover .MuiOutlinedInput-notchedOutline {
          border-color: $text-blue;
          border-width: 1px;
        }

        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: $text-blue;
          }
        }

        &.Mui-disabled {
          .MuiOutlinedInput-notchedOutline,
          &:hover .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.26);
          }
        }
      }
    }

    &.no-label {
      .MuiOutlinedInput-notchedOutline legend {
        width: 0;
      }
    }
  }
}

// RADIO FIELD

.gt-radio {
  .MuiFormControlLabel-root {
    .MuiRadio-root {
      &.Mui-checked {
        &.MuiRadio-colorPrimary {
          color: $text-blue;
        }
      }
    }

    .MuiTypography-body1 {
      font-family: "GroteskRegular";
    }
  }
}

// DATE PICKER FIELD

.gt-date-picker {
  &.outlined {
    .MuiFormControl-root.MuiTextField-root {
      width: 100%;

      .MuiInputLabel-outlined {
        font-family: $font-regular;
        font-size: 14px;
        transform: translate(14px, 12px) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(14px, -6px) scale(0.75);
        }
      }

      .MuiOutlinedInput-root {
        background-color: $white;
      }

      .MuiOutlinedInput-input {
        padding: 10px;
        font-family: $font-regular;
        font-size: 14px;
        color: $text;
      }

      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: $text-blue;
        }
      }

      &.Mui-disabled {
        .MuiOutlinedInput-notchedOutline,
        &:hover .MuiOutlinedInput-notchedOutline {
          border-color: rgba(0, 0, 0, 0.26);
        }
      }

      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: $text-blue;
        border-width: 1px;
      }
    }
  }
}

// FIELDSET

.gt-fieldset {
  .MuiFormControl-root.MuiTextField-root {
    width: 100%;

    .MuiOutlinedInput-root {
      font-family: $font-regular;
      color: $text;
      background-color: $white;

      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: $text-blue;
      }

      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: $text-blue;
        }
      }

      &.Mui-disabled {
        .MuiOutlinedInput-notchedOutline,
        &:hover .MuiOutlinedInput-notchedOutline {
          border-color: rgba(0, 0, 0, 0.26);
        }
      }
    }
  }

  &.disabled {
    .MuiFormControl-root.MuiTextField-root {
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);
      }
    }
  }
}

.MuiMenu-paper {
  .MuiMenu-list {
    .MuiMenuItem-root {
      font-family: $font-regular;
      font-size: 13px;
      color: $text;
    }
  }
}