// -----------------------------------------------------------------------------
// Font variations
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'OpenSansRegular';
  src: url('../fonts/OpenSans/OpenSans-Regular-webfont.woff') format('woff'),
       url('../fonts/OpenSans/OpenSans-Regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansLight';
  src: url('../fonts/OpenSans/OpenSans-Light-webfont.woff') format('woff'),
       url('../fonts/OpenSans/OpenSans-Light-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansSemibold';
  src: url('../fonts/OpenSans/OpenSans-Semibold-webfont.woff') format('woff'),
       url('../fonts/OpenSans/OpenSans-Semibold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansBold';
  src: url('../fonts/OpenSans/OpenSans-Bold-webfont.woff') format('woff'),
       url('../fonts/OpenSans/OpenSans-Bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gt';
  src: url('../fonts/GtIcons/gticons.woff?o8c89j') format('woff'),
       url('../fonts/GtIcons/gticons.ttf?o8c89j') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GroteskRegular';
  src: url('../fonts/Grotesk/hkgrotesk-regular-webfont.woff2') format('woff2'),
       url('../fonts/Grotesk/hkgrotesk-regular-webfont.woff') format('woff'),
       url('../fonts/Grotesk/hkgrotesk-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GroteskBold';
  src: url('../fonts/Grotesk/hkgrotesk-bold-webfont.woff2') format('woff2'),
       url('../fonts/Grotesk/hkgrotesk-bold-webfont.woff') format('woff'),
       url('../fonts/Grotesk/hkgrotesk-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GroteskLight';
  src: url('../fonts/Grotesk/hkgrotesk-light-webfont.woff2') format('woff2'),
       url('../fonts/Grotesk/hkgrotesk-light-webfont.woff') format('woff'),
       url('../fonts/Grotesk/hkgrotesk-light-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GroteskMedium';
  src: url('../fonts/Grotesk/hkgrotesk-medium-webfont.woff2') format('woff2'),
       url('../fonts/Grotesk/hkgrotesk-medium-webfont.woff') format('woff'),
       url('../fonts/Grotesk/hkgrotesk-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
