@import '../../../../../../css/styles/configs';

.gt-user-acc {
  color: $text;
  font-size: 13px;
  width: 100%;

  &:hover {
    .gt-user-acc-contents {
      border: 1px solid $text-blue;
      background: #f8faff;
    }
  }

  &-contents {
    border: 1px solid #e6e6e6;
    padding: 10px;
    background: $white;
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;

    .data-item {
      display: flex;
      flex-direction: column;

      &__label {
        font-size: 11px;
        line-height: 11px;
        font-style: italic;
        color: $old-price;
      }

      &__value {
        white-space: nowrap;        
      }

      &.customer {
        width: 30%;
        margin-right: 10px;

        .data-item__value {
          color: $text-blue;
          @include ellipsis(2);
          white-space: unset;
        }
      }
    }

    &-body {
      display: flex;
      align-items: center;
      flex: 1;

      &-rest {
        flex: 1;
        display: flex;
        align-items: center;

        .data-item {
          width: 28%;
          margin-right: 10px;

          &.email {
            .data-item__value {
              color: $text-blue;
              cursor: pointer;

              &:hover {
                color: $text;
              }
            }
          }
        }
      }
    }

    &-footer {
      .user-options {
        display: flex;
        align-items: center;

        .user-btn {
          margin-right: 5px;
          display: flex;
          padding: 0 10px;

          .gt-icon {
            display: block;
            font-size: 14px;
            cursor: pointer;
            margin: auto;

            &:hover {
              color: $text-blue;
            }
          }
        }
      }
    }
  }

  &.empty {
    .add-to-cart-btn {
      opacity: .5;
      .gt-icon {
        cursor: not-allowed;
        &:hover {
          color: $text;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .gt-user-acc {
    &-contents {
      flex-direction: column;
      align-items: flex-start;

      &-footer {
        width: 100%;

        .user-options {
          justify-content: center;

          .user-btn {
            padding: 10px !important;
            flex: 1;
          }
        }
      }

      &-body {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e6e6e6;

        .data-item {
          flex-direction: row;
          align-items: center;
          width: auto;

          &__label {
            margin-right: 5px;
            width: 80px;
          }

          &.username, &.password, &.email {
            width: auto;
          }

          &.customer {
            width: 100%;
            margin-right: 0;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 5px;

            .data-item__label {
              display: none;
            }
          }
        }

        &-rest {
          display: grid;
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 501px) and (max-width: 900px) {
  .gt-user-acc {
    &-contents {
      &-body {
        flex-direction: column;
        align-items: flex-start;
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #e6e6e6;

        .data-item {
          flex-direction: row;
          align-items: center;
          width: auto;

          &__label {
            margin-right: 5px;
            width: 80px;
          }

          &.username, &.password, &.email {
            width: auto;
          }

          &.customer {
            width: 100%;
            margin-right: 0;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 5px;

            .data-item__label {
              display: none;
            }
          }
        }

        &-rest {
          display: grid;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 901px) {
  .gt-user-acc {
    &-contents {
      &-body {
        .data-item {
          &__value {
            white-space: unset;
          }
        }
      }

      &-footer {
        .user-options {
          .user-btn {
            padding: 0 20px;
          }
        }
      }
    }
  }
}