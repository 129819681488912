.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
}

@media (min-width: 360px) {
  .container {
    width: 100%;
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 100%;
    padding: 0 10px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 100%;
    padding: 0 10px;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

/*@media (min-width: 1400px) {
  .container {
    width: 1450px;
  }
}

@media (min-width: 1620px) {
  .container {
    width: 1650px;
  }
}*/