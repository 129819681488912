@import '../../../../../../css/styles/configs';

/* ITEMS PER PAGE OPTION */
.option-items-per-page {
  display: flex;

  span {
    background-color: transparent;
    border: 1px solid $gray-dark;
    background-color: $white;
    transition: all .3s linear;
    outline: none;
    border-radius: 2px;
    width: 28px;
    height: 28px;
    text-align: center;
    font-size: 14px;
    line-height: 28px;
    color: $text;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 8px;
    }

    &.active {
      border: 1px solid $text-blue;
      background-color: $text-blue;
      color: $white;
    }
  }
}

/* SORTING ITEMS OPTION */
/* ORDER STATUS OPTION */
.option-sorting-items,
.option-order-status-items {
  .gt-select.outlined {
    .MuiFormControl-root.MuiTextField-root {
      .MuiOutlinedInput-root {
        .MuiSelect-outlined {
          padding: 5px 32px 5px 10px;
        }
        &.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-width: 1px;
        }
      }
    }
  }
}

/* FILTER BY PRICE RANGE */
.option-price-range {
  display: flex;
  align-items: center;

  &-divider {
    margin: 0 5px;
  }

  .gt-icon {
    font-size: 23px;
    margin-left: 10px;
    cursor: pointer;

    &-right-arrow {
      color: $text-blue;
    }
    &-close-circle {
      color: $red;
    }
  }

  .gt-input.outlined .MuiTextField-root {
    .MuiInputLabel-outlined {
      font-size: 13px;
      transform: translate(14px, 8px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
      }
    }

    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        padding: 7px;
        font-size: 13px;
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }
    }
  }
}

/* FILTER BY DATE RANGE */
.option-date-range {
  display: flex;
  align-items: center;

  &-divider {
    margin: 0 5px;
  }

  .gt-icon {
    font-size: 23px;
    margin-left: 10px;
    cursor: pointer;

    &-right-arrow {
      color: $text-blue;
    }
    &-close-circle {
      color: $red;
    }
  }

  .gt-date-picker.outlined .MuiTextField-root {
    .MuiInputLabel-outlined {
      font-size: 13px;
      transform: translate(14px, 8px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
      }
    }

    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        padding: 7px;
        font-size: 13px;
        min-width: 70px;
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }
    }
  }
}

/* FILTER BY SEARCH */
.option-search-by-name {
  .gt-input.outlined .MuiTextField-root {
    .MuiInputLabel-outlined {
      font-size: 13px;
      transform: translate(14px, 8px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
      }
    }

    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        padding: 7px;
        font-size: 13px;
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
      }
    }
  }
}
