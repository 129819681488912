@import '../../../../../css/styles/configs';

.divider {
  height: 2px;
  background-color: #adc1cc;
}

.sitemap {
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: $text;

  a {
    margin: 0 0 3px;
    padding: 0;
    color: $text;

    &:hover, &:visited:hover, &:active {
      color: $text-blue;
    }
    &:visited {
      color: $text;
    }
  }

  h5 {
    margin: 0 0 20px;
    font-size: 18px;
    font-weight: 500;
    font-family: $font-semibold;
  }

  & > div:not(:first-child) {
    padding: 0 1vw;
    flex: 1;
    min-width: 200px;

    span, a {
      display: block;
      line-height: 25px;
      font-size: 0.85rem;
    }
  }

  & > div:not(.gthemija) a {
    display: inline-block;
    width: 100%;
    transition: all 0.3s ease-in-out;

    &:hover {
      padding-left: 5px;
    }
  }

  .line {
    margin-top: 5px;
    font-size: 0.85rem;
    line-height: 25px;

    &.multispan {
      span:first-child {
        font-family: $font-bold;
        margin-right: 5px;            
      }
    }
  }

  .gthemija {
    flex: 1.5;
    padding: 0 1vw;

    .footer-logo {
      display: flex;
      width: 100%;
      line-height: 70px;

      .logo {
        display: block;
        height: auto;
        max-width: 210px;
        min-width: 210px;
        font-size: 0px;
        @include background-setup('../../../../../image/logo.png', left);
      }
    }
    .social-links {
      margin-top: 20px;
      display: flex;

      a {
        margin-right: 10px;
        font-size: 16px;
        color: #333333;
        width: 40px;
        height: 40px;
        background-color: transparent; 
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        border: 1px solid $gray-dark;
        z-index: 1;
        display: flex;

        &:before {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 100%;
          background: transparent;
          transition: .5s;
          z-index: 2;
        };

        .gt-icon {
          display: block;
          margin: auto;
          position: relative;
          color: $text;
          transition: .5s;
          z-index: 3;
          font-size: 17px;
        }

        &:hover {
          .gt-icon {
            color: $white;
            transform: rotateY(360deg);
          }

          &:before {
            top: 0;
            background-color: $text-blue;
          }
        }
      }
    }
  }
}

.copyright {
  padding: 10px 0;
  display: flex;
  align-items: center;
  background-color: $gray-medium;
  color: $text;

  span {
    margin: auto;
  }
}

@media only screen and (max-width: 850px) {
  .sitemap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-gap: 15px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 736px) {
    
}
