@mixin flex-row-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-column-between {
  @include flex-row-between;
  flex-direction: column;
}

@mixin background-setup ($url, $position, $size: contain) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: $size;
  background-position: $position;
}

@mixin transition ($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin boxshadow {
  box-shadow: 0 2px 6px 0 #e0e4e7, inset -1px 0 0 0 rgba(224, 227, 231, .3), inset 0 -1px 0 0 #e0e4e7, inset 1px 0 0 0 rgba(224, 227, 231, .2);
}

@mixin ellipsis ($rows: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $rows;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin aspect-ratio($ratio-or-width, $height: null) {
  $padding: if($height, percentage($height/$ratio-or-width), percentage(1/$ratio-or-width));

  &:before {
    content:'';
    float: left;
    padding-bottom: $padding;
  }
  &:after {
    content:'';
    display: table;
    clear: both;
  } 
}
