@import '../../../../../css/styles/configs';

.similar-products {
  &-carousel {
    .carousel-initialized {
      .carousel-track {
        .carousel-item {          
          .similar-products__product {
            @include aspect-ratio(1, 1.25);

            &.full {
              @include aspect-ratio(1, 1.35);
            }

            .dummy-product-placeholder {
              visibility: hidden;
            }

            .gt-similar-product {

            }
          }
        }
      }
    }
  }
}