@import '../../../../../../css/styles/configs';

.gt-show-more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px 0 30px;

  &-line {
    height: 1px;
    width: 20vw;
    min-width: 1px;
    background: $gray-dark;
    margin: 0 10px;
  }

  &-btn {
    padding: 10px;
    border: 1px solid $gray-dark;
    background: $white;
    font-size: 10px;
    color: $text;
    text-transform: uppercase;
    cursor: pointer;

    &:hover, &:active {
      border: 1px solid $text-blue;
      color: $text-blue;
    }
  }
}