.gt-page-product {
  color: #555555;
  font-size: 13px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .gt-page-product-contents {
    border: 1px solid #e6e6e6;
    padding: 9px;
    background: #ffffff;
    width: 100%;
    position: relative;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .gt-page-product-contents-body .img-wrapper {
      position: relative;
      border: 1px solid #f5f5f5;
      overflow: hidden; }
      .gt-page-product-contents-body .img-wrapper:before {
        content: '';
        float: left;
        padding-bottom: 100%; }
      .gt-page-product-contents-body .img-wrapper:after {
        content: '';
        display: table;
        clear: both; }
      .gt-page-product-contents-body .img-wrapper img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        position: absolute;
        padding: 10px; }
      .gt-page-product-contents-body .img-wrapper.oo-stock img {
        opacity: .5; }
      .gt-page-product-contents-body .img-wrapper .img-buttons {
        position: absolute;
        bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%; }
        .gt-page-product-contents-body .img-wrapper .img-buttons-btn {
          width: 40px;
          height: 40px;
          border-radius: 5px;
          background-color: #0088cc;
          display: flex;
          margin-left: 10px;
          transform: scale(0);
          opacity: .7;
          cursor: pointer;
          transition: all 0.4s ease 0s; }
          .gt-page-product-contents-body .img-wrapper .img-buttons-btn:last-child {
            margin-right: 0; }
          .gt-page-product-contents-body .img-wrapper .img-buttons-btn:hover {
            opacity: 1;
            background-color: #03689a; }
          .gt-page-product-contents-body .img-wrapper .img-buttons-btn.wishlist.favorite:hover {
            background-color: #e83f33; }
          .gt-page-product-contents-body .img-wrapper .img-buttons-btn span {
            margin: auto;
            font-size: 20px;
            color: #ffffff; }
          .gt-page-product-contents-body .img-wrapper .img-buttons-btn .gt-loader {
            margin: auto; }
            .gt-page-product-contents-body .img-wrapper .img-buttons-btn .gt-loader .spinner {
              width: 20px;
              height: 20px; }
              .gt-page-product-contents-body .img-wrapper .img-buttons-btn .gt-loader .spinner .circle:before {
                background-color: #ffffff; }
    .gt-page-product-contents-body .line-item {
      line-height: 18px;
      font-size: 14px;
      display: block; }
      .gt-page-product-contents-body .line-item.name {
        color: #555555;
        font-family: "GroteskMedium";
        font-weight: 600;
        min-height: 35px;
        max-height: 35px;
        cursor: pointer;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 5px 0 2px;
        text-align: center; }
        .gt-page-product-contents-body .line-item.name:hover {
          color: #0088cc; }
      .gt-page-product-contents-body .line-item.category {
        display: block;
        font-size: 11px;
        color: #b5b5b5; }
    .gt-page-product-contents-footer {
      position: relative; }
      .gt-page-product-contents-footer .price {
        font-size: 12px;
        padding: 5px 0 15px;
        text-align: center; }
        .gt-page-product-contents-footer .price .display-price {
          font-family: "GroteskMedium";
          color: #03689a;
          font-size: 17px; }
        .gt-page-product-contents-footer .price .old-price {
          color: #b5b5b5;
          text-decoration: line-through;
          font-weight: normal;
          margin-left: 10px; }
      .gt-page-product-contents-footer .product-options {
        display: flex;
        align-items: center; }
        .gt-page-product-contents-footer .product-options .gt-input.outlined {
          margin-right: 10px;
          flex: 1; }
          .gt-page-product-contents-footer .product-options .gt-input.outlined .MuiOutlinedInput-input {
            padding: 8px; }
        .gt-page-product-contents-footer .product-options .add-to-cart-btn button {
          width: 100%; }
          .gt-page-product-contents-footer .product-options .add-to-cart-btn button .gt-icon {
            font-size: 20px; }
          .gt-page-product-contents-footer .product-options .add-to-cart-btn button .gt-loader .spinner {
            width: 20px;
            height: 20px; }
            .gt-page-product-contents-footer .product-options .add-to-cart-btn button .gt-loader .spinner .circle:before {
              background-color: #555555; }
    .gt-page-product-contents-notification {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%; }
      .gt-page-product-contents-notification .notification-body {
        height: 81px;
        background-color: #f5f5f5;
        display: flex; }
        .gt-page-product-contents-notification .notification-body-message {
          margin: auto;
          padding: 10px 15px;
          display: flex;
          align-items: center;
          flex-direction: column; }
          .gt-page-product-contents-notification .notification-body-message .gt-icon {
            margin-bottom: 5px;
            font-size: 18px;
            color: #0088cc; }
          .gt-page-product-contents-notification .notification-body-message .message {
            line-height: 15px;
            text-align: center; }
  .gt-page-product:hover .img-buttons-btn {
    transform: scale(1); }
  .gt-page-product.loading .img-buttons-btn {
    transform: scale(1); }
  .gt-page-product.empty .add-to-cart-btn button {
    cursor: not-allowed; }

/*@media (max-width: 1024px) {
  .gt-page-product {
    &-contents-body .img-wrapper .img-buttons-btn {
      transform: scale(1);
    }
  }
}*/
@media (max-width: 439px) {
  .gt-page-product-contents-body .line-item.name {
    font-size: 15px;
    line-height: 120%;
    max-height: unset; } }
