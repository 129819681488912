@import '../../../../../../css/styles/configs';

.gt-form-wrapper {
  max-width: 600px;
  margin: 0 auto;
  position: relative;

  &.loading .gt-form {
    opacity: 0.3;
  }

  .gt-form {
    .gt-form-field {
      margin-bottom: 15px;

      &.error {
        .MuiFormLabel-root,
        .MuiFormLabel-root.Mui-focused {
          color: $red;
        }
        .MuiInputBase-root .MuiOutlinedInput-notchedOutline,
        .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: $red;
        }

        .field-error {
          font-size: 10px;
          color: $red;
          padding-left: 5px;
        }
      }
    }

    .gt-form-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .link-btn {
        color: $text;
        font-size: 13px;
        cursor: pointer;
        @include transition(all 0.3s linear);

        &:hover {
          color: $text-blue;
        }
      }
    }
  }

  .gt-form-messages {
    margin-top: 10px;

    .message {
      margin-top: 5px;
      padding: 5px 0;
      font-size: 12px;
      color: $text-blue;
      display: flex;
      align-items: flex-start;

      &.error {
        color: $red;
      }

      span {
        &:first-child {
          margin-top: 2px;
        }
        &:last-child {
          margin-left: 5px;
          line-height: 17px;
        }
      }
    }
  }

  .gt-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;

    .spinner {
      margin: auto;
    }
  }
}