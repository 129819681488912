@import '../../../../../../../css/styles/configs';

.attachment {
  border: 1px solid #e6e6e6;
  background: $white;
  position: relative;
  display: flex;

  &__main {
    flex: 1;
    display: flex;
    align-items: center;
    min-height: 80px;
    padding: 9px;

    .file-type {
      display: block;
      width: 40px;
      height: 40px;

      &.pdf {
        @include background-setup ('../../../../../../../image/file-types/pdf-file.svg', center);
      }
      &.doc, &.docx {
        @include background-setup ('../../../../../../../image/file-types/doc-file.svg', center);
      }
      &.xls, &.xlsx {
        @include background-setup ('../../../../../../../image/file-types/xls-file.svg', center);
      }
      &.jpg, &.jpeg, &.png, &.gif, &.svg {
        @include background-setup ('../../../../../../../image/file-types/img-file.svg', center);
      }
      &.mov, &.wmv, &.mp4 {
        @include background-setup ('../../../../../../../image/file-types/video-file.svg', center);
      }
      &.zip {
        @include background-setup ('../../../../../../../image/file-types/zip-file.svg', center);
      }
      &.rar {
        @include background-setup ('../../../../../../../image/file-types/rar-file.svg', center);
      }
    }

    .file-name {
      flex: 1;
      color: $text;
      margin-left: 5px;
      font-family: $font-regular;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 18px;
      word-break: break-all;
    }
  }

  &__actions {
    width: 40px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e6e6e6;

    & > div {
      flex: 1;
      display: flex;
      cursor: pointer;
      transition: all .2s linear;

      span {
        margin: auto;
        text-align: center;
        font-size: 18px;
        color: $text;
        transition: all .2s linear;
      }

      &:hover span {
        color: $white;
      }

      &.action-remove:hover {
        background: $red;
      }
      &.action-download:hover {
        background: $text-blue;
      }

      .gt-loader {
        margin: auto;
        .spinner {
          width: 18px;
          height: 18px;
        }
      }
    }

    &.disabled {
      & > div {
        cursor: default;
        &:hover {
          background: transparent;
          span {
            color: $text;
          }
        }
      }
    }
  }
}
