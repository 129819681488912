@import '../../../../../../../css/styles/configs';
@import './Animations.scss';

@mixin bouncingDots($color: $text-blue) {
  .bouncingDots {
    width: 70px;
    text-align: center;

    .bounce {
      width: 18px;
      height: 18px;
      background-color: $color;

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: bounceDelay 1.4s infinite ease-in-out both;
      animation: bounceDelay 1.4s infinite ease-in-out both;

      &-1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
      }
      &-2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
      }
    }
  }
}
