@import '../../../../../../css/styles/configs';

.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialogTitle-root {
      border-bottom: 2px solid #adc1cc;
    }
  }
}

.gt-acc-action-dialog {
  margin-top: 12px;

  .are-you-sure,
  .success,
  .error {
    display: block;
    text-align: center;
    font-size: 15px;
    color: $text;
  }

  .error {
    color: $red;
  }

  &.delete {
    .account-data {
      padding: 10px;
      margin: 30px 10px;
      border: 1px solid #e6e6e6;
      position: relative;

      &.processing span {
        opacity: .3;
      }

      span {
        display: block;
        text-align: center;
      }

      &__label {
        color: $old-price;
        line-height: 1;
      }

      &__value {
        font-size: 15px;
        color: $text-blue;
        margin-bottom: 10px;
      }

      .gt-loader {
        &.delete-loader {
          @include position-center;
        }
      }
    }
  }

  &.edit {
    .account-form {
      .gt-form-footer {
        margin-top: 45px;
        display: flex;

        .gt-button {
          margin: auto;
        }
      }
    }
  }

  .account-action,
  .dialog-action {
    display: flex;
    justify-content: center;
  }

  .dialog-action {
    margin-top: 30px;
  }
}