@import '../../../../../../../css/styles/configs';

.upload-content {
  position: relative;

  .ongoing-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;

    & > span {
      margin-top: 10px;
      font-size: 14px;
      color: $text;
    }
  }
}

.MuiDropzoneArea-root {
  &.gt-files-upload {
    border-width: 2px;
    min-height: 150px;
    display: flex;

    .MuiDropzoneArea-textContainer {
      margin: auto;

      .gt-dropzone-text {
        font-size: 14px;
      }
      .MuiDropzoneArea-icon {
        color: $text-blue;
        width: 40px;
        height: 40px;
      }
    }
  }
}
