@import '../../../../../css/styles/configs';

.products-page {
  position: relative;

  .gt-loader {
    &.products-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .page-products {
    &.grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      grid-gap: 20px;

      .page-products__product {
        position: relative;
        @include aspect-ratio(1, 1.25);

        &.full {
          @include aspect-ratio(1, 1.6);
        }
      }
    }

    &.list {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 5px;
    }
  }
}

/* ADJUSTING NO OF CARDS IN A ROW */

@media (max-width: 439px) {
  .products-page {
    .page-products {
      &.grid {
        grid-template-columns: repeat(1, minmax(300px, 300px));
        justify-content: center;

        .page-products__product {
          @include aspect-ratio(1, 1.17);

          &.full {
            @include aspect-ratio(1, 1.45);
          }
        }
      }
    }
  }
}

@media (min-width: 440px) and (max-width: 660px) {
  .products-page {
    .page-products {
      &.grid {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
      }
    }
  }
}

@media (min-width: 661px) and (max-width: 900px) {
  .products-page {
    .page-products {
      &.grid {
        grid-template-columns: repeat(3, minmax(200px, 1fr));
      }
    }
  }
}

/* ADJUSTING CARD HEIGHTS */

@media (min-width: 470px) and (max-width: 580px) {
  .products-page {
    .page-products {
      &.grid {
        .page-products__product {
          /*@include aspect-ratio(1, 1.1);*/

          &.full {
            @include aspect-ratio(1, 1.55);
          }
        }
      }
    }
  }
}

@media (min-width: 581px) and (max-width: 660px) {
  .products-page {
    .page-products {
      &.grid {
        .page-products__product {
          /*@include aspect-ratio(1, 1.1);*/

          &.full {
            @include aspect-ratio(1, 1.5);
          }
        }
      }
    }
  }
}

@media (min-width: 661px) and (max-width: 780px) {
  .products-page {
    .page-products {
      &.grid {
        .page-products__product {
          /*@include aspect-ratio(1, 1.1);*/

          &.full {
            @include aspect-ratio(1, 1.6);
          }
        }
      }
    }
  }
}

@media (min-width: 781px) and (max-width: 870px) {
  .products-page {
    .page-products {
      &.grid {
        .page-products__product {
          /*@include aspect-ratio(1, 1.1);*/

          &.full {
            @include aspect-ratio(1, 1.5);
          }
        }
      }
    }
  }
}

@media (min-width: 871px) and (max-width: 959px) {
  .products-page {
    .page-products {
      &.grid {
        .page-products__product {
          /*@include aspect-ratio(1, 1.1);*/

          &.full {
            @include aspect-ratio(1, 1.45);
          }
        }
      }
    }
  }
}
