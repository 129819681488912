@import '../../../../../css/styles/configs';

.main-page {
  &-first.intro {
    @include flex-row-between;
    margin-top: -15px;

    .mp-slide-show {
      width: 75%;
      @include aspect-ratio(16, 8);
    }

    .mp-specials-banner {
      width: 25%;
    }
  }

  &-second.about {
    margin-top: 30px;
  }

  &-third.specials {
    margin-top: 30px;
    display: grid;
    grid-template-columns: minmax(125px, 1fr) 7fr;
    grid-gap: 20px;
  }
}

@media (max-width: 600px) {
  .main-page {
    
  }
}

@media (max-width: 900px) {
  .main-page {
    &-first.intro {
      flex-direction: column;

      .mp-slide-show {
        width: 100%;
      }
      .mp-specials-banner {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .main-page {
    &-third.specials {
      display: block;

      .sidebar-banners {
        display: none;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .main-page {
    
  }
}