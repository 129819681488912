@import '../../../../../css/styles/configs';

.util-customers {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;

  &-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0;

    .action-search {
      flex: 1;
      min-width: 170px;
      max-width: 250px;
      margin-right: 20px;
    }

    .action-add-new {
      .gt-button .MuiButton-root {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 900px) {
  .util-customers {
    grid-gap: 10px;
  }
}
