@import '../../../../../../css/styles/configs';

.page-products-bulk {
  margin-top: 15px;

  &-content {
    border: 1px solid #e6e6e6;
    padding: 5px 20px;
    background: $bg-gray-light;
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-data {
      .info {
        height: 38px;
        display: flex;
        align-items: center;

        &-message {
          display: flex;
          align-items: center;
          color: $text;

          .gt-icon {
            margin-right: 5px;
            font-size: 18px;
            color: $text-blue;
          }
          .message {
            line-height: 18px;
            text-align: center;
          }
        }
      }
    }

    &-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      border-left: 1px solid $gray-darker;
      padding-left: 20px;
      margin-left: 20px;

      &:hover span {
        color: $text-blue;
      }

      span {
        color: $text;

        &:nth-child(1) {
          font-size: 20px;
        }
        &:nth-child(2) {
          text-transform: uppercase;
          margin-left: 10px;
        }
      }

      .gt-loader .spinner {
        width: 20px;
        height: 20px;

        .circle:before {
          background-color: $text;
        }
      }
    }
  }

  &.empty {
    .page-products-bulk-content-button {
      span {
        opacity: .5;
      }
      &:hover {
        cursor: not-allowed;
        span {
          color: $text;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .page-products-bulk {
    &-content {
      flex-direction: column;
      align-items: center;
      padding: 20px;

      &-button {
        border-left: 0;
        padding-left: 0;
        margin-left: 0;
        border-top: 1px solid $gray-darker;
        padding-top: 20px;
        margin-top: 20px;
      }
    }
  }
}
