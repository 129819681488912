@import '../../../../../../css/styles/configs';

.sidebar-banners {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .banner {
    height: 100%;
    margin: 5px 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    &.empty {
      border: 1px dashed $gray-darker;
      display: flex;

      .banner-img {
        @include background-setup('../../../../../../image/banners/empty.png', center, 60%);
      }
    }

    .banner-img {
      display: block;
      height: 100%;
      width: 100%;
    }

    &.meridianbet .banner-img {
      @include background-setup('../../../../../../image/banners/specijalna-bolnica-beograd-new.png', center, cover);
    }

    &.institutzastranejezicke .banner-img {
      @include background-setup('../../../../../../image/banners/institur-za-strane-jezike.jpg', center, cover);
    }
    &.gigatron .banner-img {
      @include background-setup('../../../../../../image/banners/gigatron.jpg', center, cover);
    }
  }
}