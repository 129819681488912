@import '../../../../../css/styles/configs';

.login-page {

  .login-message,
  .available-forms {
    padding-bottom: 50px;
    margin: 40px 0 0;
    text-align: center;
    font-size: 18px;
    color: $text;
    line-height: normal;
    font-weight: normal;
  }

  .debt-message-wrapper {
    display: flex;
    margin-bottom: 50px;

    .debt-message {
      margin: auto;
      padding: 20px;
      min-width: 300px;
      max-width: 500px;
      border: 3px double $red;
      font-size: 15px;
      line-height: 20px;

      span {
        display: block;
        text-align: center;
        color: $text;

        &.gt-icon {
          font-size: 30px;
          color: $text-blue;
          margin-bottom: 20px;
        }

        &:nth-child(3) {
          color: $red;
          font-size: 18px;
          font-size: 30px;
          line-height: 30px;
          margin: 10px 0 20px;
        }
      }
    }
  }

  .gt-form-wrapper {
    margin-bottom: 25px;
  }

  .available-forms {
    display: flex;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 40px;
    font-size: 16px;

    span {
      cursor: pointer;
      position: relative;

      &:last-child {
        margin-left: 30px;
      }

      &:after {
        content: '';
        position: relative;
        display: block;
        width: 0;
        height: 2px;
        margin-top: 6px;
        font-weight: 600;
        background-color: $text-blue;
        left: 50%;
        @include transition(all .3s ease-in);
      }

      &.active {
        &:after {
          left: 0;
          width: 100%;
        }
      }
    }
  }

}