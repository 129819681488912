@import '../../../../../../css/styles/configs';

.gt-page-product-list {
  color: $text;
  font-size: 13px;
  width: 100%;

  &-contents {
    border: 1px solid #e6e6e6;
    padding: 5px;
    background: $white;
    width: 100%;
    height: inherit;
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-gap: 10px;

    &.chosen {
      border: 1px solid $text-blue;
      background: #f8faff;
    }

    &-img {
      .img-wrapper {
        position: relative;
        border: 1px solid $gray-light;
        width: 50px;
        cursor: pointer;
        @include aspect-ratio(1, 1);

        &:hover {
          .img-button-preview {
            transform: scale(1);
          }
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          position: absolute;
          padding: 3px;
        }

        &.oo-stock img {
          opacity: .5;
        }

        .img-button-preview {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          opacity: 0;
          cursor: pointer;
          background-color: $bg-blue-dark;
          transition: all 0.4s ease 0s;

          &:hover {
            opacity: .7;
          }

          span {
            margin: auto;
            font-size: 20px;
            color: $white;
          }
        }
      }

      .line-item {
        line-height: 18px;
        font-size: 14px;
        display: block;

        &.category {
          display: block;
          font-size: 11px;
          color: #b5b5b5;
        }
      }
    }

    &-data {
      flex: 1;
      display: flex;
      align-items: center;

      .list-item {
        display: flex;
        flex-direction: column;

        &__label {
          font-size: 11px;
          line-height: 11px;
          font-style: italic;
          color: $old-price;
        }

        &__value {
          
        }

        &.title {
          width: 40%;
          margin-right: 10px;

          .list-item__value {
            color: $text-blue;
            cursor: pointer;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              color: $text;
            }
          }
        }
      }
    }

    &-body {
      display: flex;
      align-items: center;
      flex: 1;

      &-rest {
        flex: 1;
        display: flex;
        align-items: center;

        .list-item {
          width: 20%;
          margin-right: 10px;

          &.code, &.stock, &.no-stock {
            width: 15%;
          }

          &.no-stock {
            color: $red;
          }
        }
      }
    }

    &-footer {
      .product-options {
        display: flex;
        align-items: center;

        .gt-input.outlined {
          margin-right: 10px;
          flex: 1;

          .MuiOutlinedInput-input {
            padding: 8px;
            max-width: 55px;
          }
        }

        .add-to-cart-btn {
          margin-right: 5px;

          .gt-icon {
            display: block;
            width: 20px;
            height: 20px;
            font-size: 20px;
            cursor: pointer;

            &:hover {
              color: $text-blue;
            }

            &-success,
            &-success:hover {
              color: $green;
            }
          }
          .gt-loader .spinner {
            width: 20px;
            height: 20px;

            .circle:before {
              background-color: $text;
            }
          }
        }
      }

      &.oo-stock {
        .add-to-cart-btn .gt-icon,
        .add-to-cart-btn .gt-icon:hover {
          color: $red;
        }
      }
    }

    &-notification {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      .notification-body {
        height: 81px;
        background-color: $gray-light;
        display: flex;

        &-message {
          margin: auto;
          padding: 10px 15px;
          display: flex;
          align-items: center;
          flex-direction: column;

          .gt-icon {
            margin-bottom: 5px;
            font-size: 18px;
            color: $text-blue;
          }
          .message {
            line-height: 15px;
            text-align: center;
          }
        }
      }
    }
  }

  &.empty {
    .add-to-cart-btn {
      opacity: .5;
      .gt-icon {
        cursor: not-allowed;
        &:hover {
          color: $text;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .gt-page-product-list {
    &-contents {
      grid-template-columns: 100px 1fr;

      &-img .img-wrapper {
        height: 100%;
        width: 100px;
      }

      &-data {
        flex-direction: column;
        align-items: flex-start;
      }

      &-footer {
        width: 100%;
        .product-options .gt-input.outlined .MuiOutlinedInput-input {
          max-width: unset;
        }
      }

      &-body {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e6e6e6;

        .list-item {
          flex-direction: row;
          align-items: center;
          width: auto;

          &__label {
            margin-right: 5px;
            width: 80px;
          }

          &.code, &.stock, &.no-stock {
            width: auto;
          }

          &.title {
            width: 100%;
            margin-right: 0;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 5px;

            .list-item__label {
              display: none;
            }
          }
        }

        &-rest {
          display: grid;
        }
      }
    }
  }
}

@media (min-width: 501px) and (max-width: 700px) {
  .gt-page-product-list {
    &-contents {
      &-img .img-wrapper {
        height: 100%;
      }
      &-body {
        flex-direction: column;
        align-items: flex-start;
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #e6e6e6;

        .list-item {
          flex-direction: row;
          align-items: center;
          width: auto;

          &__label {
            margin-right: 5px;
            width: 80px;
          }

          &.code, &.stock, &.no-stock {
            width: auto;
          }

          &.title {
            width: 100%;
            margin-right: 0;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 5px;

            .list-item__label {
              display: none;
            }
          }
        }

        &-rest {
          display: grid;
        }
      }
    }
  }
}

@media (min-width: 701px) and (max-width: 900px) {
  .gt-page-product-list {
    &-contents {
      &-body {
        flex-direction: column;
        align-items: flex-start;
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #e6e6e6;

        .list-item {
          flex-direction: row;
          align-items: center;
          width: auto;

          &__label {
            margin-right: 5px;
          }

          &.code, &.stock, &.no-stock {
            width: auto;
          }

          &.title {
            width: 100%;
            margin-right: 0;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 5px;

            .list-item__label {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .gt-page-product-list.trimmed {

    .gt-page-product-list-contents {
      grid-template-columns: 50px 1fr;

      &-img {
        .img-wrapper {
          width: 50px;
        }
      }

      &-body {
        margin: 0;
        padding: 0;
        border: 0;
      }

      &-data {
        flex-direction: row;
        align-items: center;
      }
    }

    .list-item.title {
      width: auto;
      margin-right: 0;
      border-bottom: 0;
      margin-bottom: 0;
      flex-direction: column;
      align-items: flex-start;

      .list-item__label {
        display: block;
      }
    }
  }
}