@import '../../../../../css/styles/configs';

.product-page {
  position: relative;

  .gt-loader {
    &.products-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__product {
    &-section-1 {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 50px;

      .img-wrapper {
        position: relative;
        background: $white;
        border: 1px solid $new-border;
        overflow: hidden;
        @include aspect-ratio(1, 1);

        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          position: absolute;
          padding: 20px;
        }
      }

      .product-info {

        &-min-order-qty {
          .gt-icon-warning {
            margin-right: 5px;
          }

          position: relative;
          border-bottom: 1px solid $gray-dark;
          margin: 0 0 15px 0;
          padding: 15px 0;
          color: $orange;
          font-weight: bold;
        }

        &-item {
          color: $text;
          display: flex;
          align-items: flex-start;

          &-label {
            min-width: 150px;
            font-size: 0.8rem;
            line-height: 1.2rem;
            margin-left: 5px;
            text-transform: uppercase;
            color: $old-price;
          }

          &-value {
            margin-left: 5px;
            color: $text;
            font-size: 0.9rem;
            line-height: 1.2rem;
          }

          &:not(.name):not(.price) {
            margin-bottom: 10px;
          }

          &.name {
            span {
              margin-left: 0;
              font-size: 1.2rem;
            }
          }

          &.price {
            font-size: 18px;
            margin-top: 20px;
            align-items: center;
            line-height: 1.5rem;
            flex-wrap: wrap;

            .without-vat {
              color: $text-darkblue;
              font-size: 1.2rem;
              font-family: "GroteskMedium";
            }
            .with-vat {
              color: $old-price;
              font-size: 1rem;
              margin-left: 5px;
            }
          }

          &.category {
            a, a:visited {
              color: $text;
              cursor: pointer;

              &:hover {
                color: $text-blue;
              }
            }
          }
        }

        &-add-to-cart {
          position: relative;
          /*border-top: 1px solid $gray-dark;*/
          border-bottom: 1px solid $gray-dark;
          margin: 10px 0;
          margin-top: 20px;
          padding: 15px 0;
          display: flex;
          align-items: center;

          &.unavailable {
            border-bottom: 0;
            padding: 0;
          }

          &-inputs {
            margin-right: 30px;
          }

          .add-to-cart-btn button {
            width: 100%;
            padding: 8px 16px;

            .gt-icon {
              font-size: 20px;
            }
            .gt-icon-label {
              margin-left: 10px;
            }

            .gt-loader .spinner {
              width: 20px;
              height: 20px;

              .circle:before {
                background-color: $text;
              }
            }
          }
        }
        &-notification {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;

          .notification-body {
            height: 69px;
            background-color: $gray-light;
            display: flex;

            &-message {
              margin: auto;
              padding: 10px 15px;
              display: flex;
              align-items: center;
              flex-direction: column;

              .gt-icon {
                margin-bottom: 5px;
                font-size: 18px;
                color: $text-blue;
              }
              .message {
                line-height: 15px;
                text-align: center;
              }
            }
          }
        }

        &-favorite {
          border-bottom: 1px solid #d8d8d8;
          margin-bottom: 20px;
          padding-bottom: 10px;
          display: flex;

          &-btn {
            display: flex;
            align-items: center;
            margin-left: 5px;
            color: $text;
            cursor: pointer;

            &:hover {
              color: $text-blue;
            }

            &.loading {
              opacity: .5;
              &:hover {
                color: $text;
                cursor: not-allowed;
              }
            }

            span {
              font-size: 0.9rem;

              &.gt-icon {
                font-size: 18px;
                margin-right: 5px;
              }
            }

            .gt-loader {
              margin-right: 5px;

              .spinner {
                width: 18px;
                height: 18px;

                .circle:before {
                  background-color: $text;
                }
              }
            }
          }
        }

        &-share {
          border-bottom: 1px solid #d8d8d8;
          border-top: 1px solid #d8d8d8;
          padding: 10px 0;
          display: flex;
          align-items: flex-start;

          &-text {
            min-width: 150px;
            font-size: 0.8rem;
            line-height: 1.8rem;
            margin-left: 5px;
            text-transform: uppercase;
            color: #b5b5b5;
          }

          .gt-social-share {
            margin-left: 5px;
            display: flex;
            flex-wrap: wrap;

            button {
              display: flex;
              margin: 4px;
            }
          }
        }
      }
    }

    &-section-2 {
      margin-top: 50px;

      .gt-section-header {
        margin-bottom: 20px;

        .header-title h2 {
          font-size: 1rem;
        }
      }

      .product-info-item {
        display: flex;
        flex-direction: column;
        font-size: 0.9rem;
        line-height: 1.2rem;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &-label {
          color: $old-price;
          margin-bottom: 5px;

          &:before {
            font-family: 'gt';
            margin-right: 5px;
          }
        }

        &-value {
          color: $text;
        }

        &.purpose-spec .product-info-item-label:before {
          content: "\e91c";
        }
        &.instructions-spec .product-info-item-label:before {
          content: "\e925";
        }
        &.composition-spec .product-info-item-label:before {
          content: "\e926";
        }
        &.warning-spec .product-info-item-label:before {
          content: "\e91d";
        }
      }
    }

    &-section-3 {
      margin-top: 50px;
    }

    &-section-4 {
      margin-top: 50px;
      margin-bottom: 20px;
    }

    &.trimmed {
      .product-page__product-section-1 {
        .product-info {
          &-item {
            flex-direction: column;
            margin-bottom: 20px;

            &-label:before {
              font-family: 'gt';
              margin-right: 5px;
            }

            &.name {
              border-bottom: 1px solid $gray-light;
              margin-bottom: 20px;
              padding-bottom: 20px;
            }

            &.purpose-spec .product-info-item-label:before {
              content: "\e91c";
            }
            &.instructions-spec .product-info-item-label:before {
              content: "\e925";
            }
            &.composition-spec .product-info-item-label:before {
              content: "\e926";
            }
            &.warning-spec .product-info-item-label:before {
              content: "\e91d";
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .product-page {
    &__product {
      &-section-1 {
        .product-info {

          .product-info-add-to-cart {
            .add-to-cart-btn .gt-icon-label {
              display: none;
            }
          }

          &-item {
            &-label {
              min-width: 110px;
              font-size: 0.7rem;
            }
            &.name span {
              width: 100%;
              text-align: center;
              font-size: 1rem;
            }
            &.price {
              justify-content: center;
            }
          }

          &-add-to-cart {
            justify-content: center;
            padding-bottom: 25px;
          }

          &-favorite {
            justify-content: center;
          }

          &-share {
            &-text {
              min-width: 110px;
              font-size: 0.7rem;
            }
          }
        }
      }

      &-section-2 {
        margin-top: 30px;
      }
    }
  }
}

@media (max-width: 950px) {
  .product-page {
    &__product {
      &-section-1 {
        .product-info {
          &-item.purpose {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .product-page {
    &__product {
      &-section-1 {
        grid-gap: 20px;
      }
    }
  }
}
