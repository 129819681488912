@import '../../../../../../css/styles/configs';

.gt-carousel {

  &.section {
    height: 100%;

    & > div,
    .carousel-initialized,
    .carousel-track,
    .carousel-item,
    .carousel-row,
    .carousel-row > div {
      /*height: 100% !important;*/
    }

    .carousel-initialized {
      overflow: unset;

      .carousel-track {
        overflow: hidden;
      }

      .carousel-arrow {
        top: -40px;
        height: 25px;

        .gt-icon {
          font-size: 16px;
          color: $text;

          &:hover {
            color: $text-blue;
          }
        }

        &.carousel-prev {
          left: auto;
          right: 25px;
        }
      }
    }
  }
}