@import '../../../../../../css/styles/configs';

.gt-scroll-to-top {
  & > div {
    z-index: 9999;
  }
  .gt-icon {
    &.gt-icon-circle-arrow-up {
      font-size: 2rem;
      color: $text;

      &:hover {
        color: $text-blue;
      }
    }
  }
}
