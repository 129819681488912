@import '../../../../../../../css/styles/configs';
@import './Animations.scss';

@mixin stretchingBars($color: $text-blue) {
  .stretchingBars {
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;

    .rect {
      background-color: $color;
      height: 100%;
      width: 6px;
      margin: 0 3px 0 0;
      display: inline-block;
      -webkit-animation: stretchDelay 1.2s infinite ease-in-out;
      animation: stretchDelay 1.2s infinite ease-in-out;

      &-2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
      }
      &-3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
      }
      &-4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }
      &-5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
      }
    }
  }
}
