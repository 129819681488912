@import '../../../../../css/styles/configs';

.history-page {
  position: relative;

  .gt-loader {
    &.history-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .gt-page-header {
    margin: 10px 0 40px;
  }

  .gt-accordion.order-history {
    margin-top: 10px;

    .MuiExpansionPanel-rounded {
      border: 1px solid transparent;
      border-bottom: 1px solid #e6e6e6;
      padding: 5px 10px;
      background: $white;
      border-radius: 0;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:nth-child(1):before {
        display: block;
        top: -10px;
        left: 0;
        right: 0;
        height: 1px;
        content: "";
        opacity: 1;
        position: absolute;
        -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #e6e6e6;
      }

      &.MuiExpansionPanel-root {
        &:not(:first-child):before {
          background-color: transparent;
        }

        &.Mui-expanded {
          margin-top: 10px;
          border: 1px solid #0088cc;

          &:after {
            bottom: -11px;
            left: 0;
            right: 0;
            height: 1px;
            content: "";
            opacity: 1;
            position: absolute;
            transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            background-color: #e6e6e6;
          }
        }
      }

      &.MuiPaper-root {
        color: $text;
      }

      &.MuiPaper-elevation1 {
        box-shadow: unset;
      }

      .MuiExpansionPanelSummary-root {
        padding: 0;
      }

      .MuiExpansionPanelSummary-content {
        border-right: 1px solid #e6e6e6;
        padding-right: 12px;
        justify-content: space-between;

        .MuiTypography-body1 {
          font-family: $font-regular;
          font-size: 13px;
          color: $text;
        }

        .heading-item {
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;

          &__label {
            font-size: 11px;
            line-height: 11px;
            font-style: italic;
            color: $old-price;
          }

          &.status {
            align-items: flex-end;

            span:last-child {
              display: flex;
              align-items: center;

              &:after {
                content: '';
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin-left: 5px;
                margin-top: 2px;
              }
            }

            &.unknown span:last-child:after {
              background: #e7e6e4;
            }
            &.created span:last-child:after {
              background: #19f0d0;
            }
            &.altered span:last-child:after {
              background: #d482ca;
            }
            &.preparing span:last-child:after {
              background: #ffe257;
            }
            &.packing span:last-child:after {
              background: #fdaf68;
            }
            &.packed span:last-child:after {
              background: #bfebf2;
            }
            &.ready span:last-child:after {
              background: #cfffb8;
            }
            &.transport span:last-child:after {
              background: #cefc31;
            }
            &.delivered span:last-child:after {
              background: #15d115;
            }
            &.canceled span:last-child:after {
              background: $red;
            }
            &.deleted span:last-child:after {
              background: #967e69;
            }
            &.realized span:last-child:after {
              background: $text-blue;
            }
          }
        }

        .heading-data {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .heading-item {
            &:not(:last-child) {
              max-width: 25%;
              min-width: 150px;
            }
          }
        }
      }

      .MuiCollapse-container {
        .MuiExpansionPanelDetails-root {
          padding: 15px;
          background-color: #f8faff;

          .body-data {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 5px;
            position: relative;

            &-item {
              display: flex;
              align-items: flex-start;
              padding: 5px;

              &:not(:last-child) {
                border-bottom: 1px solid #e6e6e6;
              }

              .heading-item {
                display: flex;
                flex-direction: column;

                &__label {
                  font-size: 11px;
                  line-height: 11px;
                  font-style: italic;
                  color: $old-price;
                }

                &.title {
                  width: 40%;
                  margin-right: 10px;
                  .heading-item__value {
                    color: $text-blue;
                  }
                }
              }

              &-rest {
                flex: 1;
                display: flex;
                align-items: flex-start;

                .heading-item {
                  flex: 1;
                }
              }
            }

            .totals {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              background: #e8eefd;

              &-item {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                width: 360px;

                span {
                  width: 50%;
                  text-align: right;

                  &:first-child {
                    font-size: 14px;
                    color: $text;
                  }
                  &:last-child {
                    font-size: 16px;
                    color: $black;
                  }
                }

                &:last-child {
                  margin-top: 10px;
                  padding-top: 10px;
                  border-top: 1px solid $gray-darker;

                  span:last-child {
                    color: $text-blue;
                  }
                }
              }
            }

            &.in-progress .body-data-item {
              opacity: .4;
            }

            .gt-loader.repeating-loader {
              @include position-center;

              .rect {
                width: 3px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .history-page {
    .gt-accordion.order-history {
      .MuiExpansionPanel-rounded {
        .MuiCollapse-container {
          .MuiExpansionPanelDetails-root {
            .body-data {
              &-item, &-item-rest {
                flex-direction: column;
                align-items: flex-start;

                .heading-item, .heading-item.title {
                  width: 100%;
                  flex-direction: row;
                  align-items: center;

                  &__label {
                    margin-right: 5px;
                    width: 80px;
                  }
                }

                .heading-item.title {
                  .heading-item__value {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .history-page {
    .gt-accordion.order-history {
      .MuiExpansionPanel-rounded {
        .MuiCollapse-container {
          .MuiExpansionPanelDetails-root {
            .body-data {
              .totals {
                width: 100%;

                &-item {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
