@import '../../../../../css/styles/configs';

.gt-brands {
  background-color: $white;
  border-top: 1px solid $gray-dark;
  padding: 15px 0;

  &-carousel {
    .carousel-initialized {
      .carousel-arrow {
        display: none;
      }

      .carousel-track {
        .carousel-item {          
          cursor: pointer;

          &:hover .gt-brand {
            filter: unset;
            opacity: 1;
          }

          .gt-brand {
            display: block;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            height: 60px;
            padding: 2%;
            position: relative;
            text-align: center;
            filter: grayscale(1);
            opacity: .7;
            transition: all .4s linear;
          }

          &:nth-child(1) .gt-brand {
            background-image: url('../../../../../image/brands/brand-1.png');
          }
          &:nth-child(2) .gt-brand {
            background-image: url('../../../../../image/brands/brand-2.png');
          }
          &:nth-child(3) .gt-brand {
            background-image: url('../../../../../image/brands/brand-3.png');
          }
          &:nth-child(4) .gt-brand {
            background-image: url('../../../../../image/brands/brand-4.png');
          }
          &:nth-child(5) .gt-brand {
            background-image: url('../../../../../image/brands/brand-5.png');
          }
          &:nth-child(6) .gt-brand {
            background-image: url('../../../../../image/brands/brand-6.png');
          }
          &:nth-child(7) .gt-brand {
            background-image: url('../../../../../image/brands/brand-7.png');
          }
          &:nth-child(8) .gt-brand {
            background-image: url('../../../../../image/brands/brand-8.png');
          }
          &:nth-child(9) .gt-brand {
            background-image: url('../../../../../image/brands/brand-9.png');
          }
          &:nth-child(10) .gt-brand {
            background-image: url('../../../../../image/brands/brand-10.png');
          }
          &:nth-child(11) .gt-brand {
            background-image: url('../../../../../image/brands/brand-11.png');
          }
          &:nth-child(12) .gt-brand {
            background-image: url('../../../../../image/brands/brand-12.png');
          }
          &:nth-child(13) .gt-brand {
            background-image: url('../../../../../image/brands/brand-13.png');
          }
          &:nth-child(14) .gt-brand {
            background-image: url('../../../../../image/brands/brand-14.png');
          }
          &:nth-child(15) .gt-brand {
            background-image: url('../../../../../image/brands/brand-15.png');
          }
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .gt-brands {
    &-carousel {
      .carousel-initialized {
        .carousel-track {
          .carousel-item {          
            .gt-brand {
              height: 40px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 751px) and (max-width: 900px) {
  .gt-brands {
    &-carousel {
      .carousel-initialized {
        .carousel-track {
          .carousel-item {          
            .gt-brand {
              height: 50px;
            }
          }
        }
      }
    }
  }
}
