@import '../../../../../css/styles/configs';

.cart-page {
  position: relative;

  .gt-loader {
    &.products-loader {
      @include position-center;
    }
  }

  .cart-products {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;

    &__product {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 10px;
      position: relative;

      &.changing {
        .cart-products__product-contents {
          opacity: .2;
        }
      }

      .gt-loader.qty-loader {
        @include position-center;

        .rect {
          width: 3px;
        }
      }

      &-contents {
        padding: 5px;
        background: $white;
        width: 100%;
        height: inherit;
        display: grid;
        grid-template-columns: 100px 1fr;
        grid-gap: 10px;

        &-img {
          .img-wrapper {
            position: relative;
            /*border: 1px solid $gray-light;*/
            width: 100px;
            @include aspect-ratio(1, 1);

            &:hover {
              .img-button-preview {
                transform: scale(1);
              }
            }

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: scale-down;
              position: absolute;
              padding: 3px;
            }
          }
        }

        &-data, &-data-rest {
          flex: 1;
          display: flex;
          align-items: center;

          .list-item {
            display: flex;
            flex-direction: column;
            margin-right: 10px;

            &__label {
              font-size: 11px;
              line-height: 11px;
              font-style: italic;
              color: $old-price;
            }

            &.title {
              width: 40%;

              .list-item__value {
                color: $text;
                cursor: pointer;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                  color: $text-blue;
                }
              }
            }

            &.qty {
              flex-direction: row;
              .gt-input {
                min-width: 75px;
              }
            }

            &.totalprice {
              align-items: flex-end;
            }

            &:not(.title) {
              width: 25%;
            }
          }

          .gt-icon-delete {
            font-size: 16px;
            margin-left: 20px;
            color: $text;
            cursor: pointer;

            &:hover {
              color: $red;
            }
          }
        }
      }
    }

    &__totals {
      border: 1px solid #e6e6e6;
      padding: 5px 20px;
      background: #f8f8f8;
      width: 100%;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .delete-cart-btn {
        margin-right: auto;
      }

      &-items {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        order: 2;
      }

      &-item {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 360px;

        span {
          width: 50%;
          text-align: right;

          &:first-child {
            font-size: 14px;
            color: $text;
          }
          &:last-child {
            font-size: 16px;
            color: $black;
          }
        }

        &:last-child {
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid $gray-darker;

          span:last-child {
            color: $text-blue;
          }
        }
      }
    }
  }

  .cart-actions {
    padding: 15px 0;
  }

  .cart-order {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #e6e6e6;
    background: #f8f8f8;

    &-details {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 20px;

      &-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .gt-delivery-dates {
          flex: 1;
        }

        .gt-select {
          margin-top: 20px;
        }
      }
    }

    &-action {
      margin: 50px 0 20px;
      display: flex;
      flex-direction: column;

      .order-btn {
        margin: auto;
      }

      .cart-notification {
        min-height: 34px;
        display: flex;

        &-message {
          margin: auto;
          display: flex;
          align-items: flex-start;

          .gt-icon {
            margin-right: 5px;
            font-size: 18px;
            color: $text-blue;
          }
          .message {
            line-height: 15px;
            text-align: left;
            color: $text;
          }
        }

        &.error {
          margin-top: 10px;

          .cart-notification-message {
            .gt-icon, .message {
              color: $red;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .cart-page {
    .cart-products {
      &__product {
        &-contents {
          &-data {
            &-rest {
              flex-direction: column;
              align-items: flex-start;
              flex-wrap: nowrap;

              .list-item {
                &:not(.title) {
                  width: 100% !important;
                }

                &__label {
                  width: 60px;
                }

                &.qty {
                  width: auto;
                  margin: 20px 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .cart-page {
    .cart-products {
      &__product {
        &-contents {
          &-img .img-wrapper {
            height: 100%;
            width: 100px;
          }

          &-data {
            &-rest {
              flex-wrap: wrap;

              .list-item {
                width: 100%;
                flex-direction: row;
                align-items: center;
                margin: 0;

                &:not(.title) {
                  width: 33%;
                }

                &__label {
                  margin-right: 10px;
                }

                &.qty {
                  width: 100%;
                  justify-content: flex-start;
                  margin: 20px 0;
                  order: 1;
                }
                &.price {
                  order: 2;
                }
                &.vat {
                  order: 3;
                }
                &.totalprice {
                  order: 4;
                  align-items: center;
                }
              }
            }
          }
        }
      }

      &__totals {
        flex-direction: column;

        &-items {
          order: 1;
          width: 100%;
        }

        .delete-cart-btn {
          order: 2;
          margin: 50px auto 20px;
        }

        &-item {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .cart-page {
    .cart-products {
      &__product {
        border: 1px solid #e6e6e6;
      }
    }
  }
}

@media (max-width: 1199px) {
  .cart-page {
    .cart-products {
      &__product {
        border-bottom: 1px solid #e6e6e6;

        &-contents {
          &-data {
            flex-direction: column;
            align-items: flex-start;

            .list-item {
              &.title {
                width: calc(100% - 40px);
                border-bottom: 1px solid #e6e6e6;
                padding-bottom: 5px;
                margin-bottom: 5px;
                margin-right: 0px;
                padding-right: 40px;

                .list-item__label {
                  display: none;
                }
              }
            }

            &-rest {
              width: calc(100% - 40px);
            }

            .gt-icon-delete {
              position: absolute;
              right: 10px;
              top: 10px;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
