@import '../../../../../../css/styles/configs';

.gt-page-product {
  color: $text;
  font-size: 13px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &-contents {
    border: 1px solid #e6e6e6;
    padding: 9px;
    background: $white;
    width: 100%;
    position: relative;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-body {
      .img-wrapper {
        position: relative;
        border: 1px solid $gray-light;
        overflow: hidden;
        @include aspect-ratio(1, 1);

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          position: absolute;
          padding: 10px;
        }

        &.oo-stock img {
          opacity: .5;
        }

        .img-buttons {
          position: absolute;
          bottom: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          &-btn {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            background-color: $bg-blue-default;
            display: flex;
            margin-left: 10px;
            transform: scale(0);
            opacity: .7;
            cursor: pointer;
            transition: all 0.4s ease 0s;

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              opacity: 1;
              background-color: $bg-blue-dark;
            }

            &.wishlist {
              &.favorite:hover {
                background-color: $red;
              }
            }

            span {
              margin: auto;
              font-size: 20px;
              color: $white;
            }

            .gt-loader {
              margin: auto;

              .spinner {
                width: 20px;
                height: 20px;

                .circle:before {
                  background-color: $white;
                }
              }
            }
          }
        }
      }

      .line-item {
        line-height: 18px;
        font-size: 14px;
        display: block;

        &.name {
          color: $text;
          font-family: $font-semibold;
          font-weight: 600;
          min-height: 35px;
          max-height: 35px;
          cursor: pointer;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 5px 0 2px;
          text-align: center;

          &:hover {
            color: $text-blue;
          }
        }
        &.category {
          display: block;
          font-size: 11px;
          color: #b5b5b5;
        }
      }
    }

    &-footer {
      position: relative;

      .price {
        font-size: 12px;
        padding: 5px 0 15px;
        text-align: center;

        .display-price {
          font-family: $font-semibold;
          color: $text-darkblue;
          font-size: 17px;
        }

        .old-price {
          color: $old-price;
          text-decoration: line-through;
          font-weight: normal;
          margin-left: 10px;
        }
      }

      .product-options {
        display: flex;
        align-items: center;

        .gt-input.outlined {
          margin-right: 10px;
          flex: 1;

          .MuiOutlinedInput-input {
            padding: 8px;
          }
        }

        .add-to-cart-btn button {
          width: 100%;

          .gt-icon {
            font-size: 20px;
          }
          .gt-loader .spinner {
            width: 20px;
            height: 20px;

            .circle:before {
              background-color: $text;
            }
          }
        }
      }
    }

    &-notification {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      .notification-body {
        height: 81px;
        background-color: $gray-light;
        display: flex;

        &-message {
          margin: auto;
          padding: 10px 15px;
          display: flex;
          align-items: center;
          flex-direction: column;

          .gt-icon {
            margin-bottom: 5px;
            font-size: 18px;
            color: $text-blue;
          }
          .message {
            line-height: 15px;
            text-align: center;
          }
        }
      }
    }
  }

  &:hover {
    .img-buttons-btn {
      transform: scale(1);
    }
  }

  &.loading {
    .img-buttons-btn {
      transform: scale(1);
    }
  }

  &.empty {
    .add-to-cart-btn button {
      cursor: not-allowed;
    }
  }
}

/*@media (max-width: 1024px) {
  .gt-page-product {
    &-contents-body .img-wrapper .img-buttons-btn {
      transform: scale(1);
    }
  }
}*/

@media (max-width: 439px) {
  .gt-page-product {
    &-contents {
      &-body {
        .line-item.name {
          font-size: 15px;
          line-height: 120%;
          max-height: unset;
        }
      }
    }
  }
}