@import '../../../../../../css/styles/configs';

.gt-product-quick-view {
  &-section-1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;

    .img-wrapper {
      position: relative;
      border: 1px solid $gray-light;
      overflow: hidden;
      @include aspect-ratio(1, 1);

      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        position: absolute;
        padding: 10px;
      }
    }

    .product-info {
      &-item {
        font-size: 1rem;
        line-height: 1.1rem;
        display: flex;
        align-items: flex-start;

        &-label {
          min-width: 85px;
          font-size: 0.7rem;
          margin-left: 5px;
          text-transform: uppercase;
          color: $old-price;
        }

        &-value {
          margin-left: 5px;
          color: $text;
          font-size: 0.85rem;
        }

        &:not(.price) {
          margin-bottom: 10px;
        }

        &.price {
          align-items: center;
          line-height: 1.5rem;
          flex-wrap: wrap;

          .without-vat {
            color: $text-darkblue;
            font-size: 1.2rem;
          }
          .with-vat {
            color: $old-price;
            font-size: 0.8rem;
            margin-left: 5px;
          }
        }

        &.no-stock .product-info-item-label {
          color: $red;
          font-size: 0.9rem;
        }
      }

      &-add-to-cart {
        position: relative;
        border-top: 1px solid $gray-dark;
        border-bottom: 1px solid $gray-dark;
        margin: 10px 0;
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &.unavailable {
          border-bottom: 0;
          padding: 0;
        }

        &-inputs {
          margin-right: 30px;
        }

        .add-to-cart-btn button {
          width: 100%;
          padding: 8px 16px;

          .gt-icon {
            font-size: 20px;
          }
          .gt-loader .spinner {
            width: 20px;
            height: 20px;

            .circle:before {
              background-color: $text;
            }
          }
        }
      }

      &-favorite {
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: center;

        &-btn {
          display: flex;
          align-items: center;
          margin-left: 5px;
          color: $text;
          cursor: pointer;

          &:hover {
            color: $text-blue;
          }

          &.loading {
            opacity: .5;
            &:hover {
              color: $text;
              cursor: not-allowed;
            }
          }

          span {
            font-size: 13px;

            &.gt-icon {
              font-size: 18px;
              margin-right: 5px;
            }
          }

          .gt-loader {
            margin-right: 5px;

            .spinner {
              width: 18px;
              height: 18px;

              .circle:before {
                background-color: $text;
              }
            }
          }
        }
      }

      &-notification {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        .notification-body {
          height: 69px;
          background-color: $gray-light;
          display: flex;

          &-message {
            margin: auto;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            flex-direction: column;

            .gt-icon {
              margin-bottom: 5px;
              font-size: 18px;
              color: $text-blue;
            }
            .message {
              line-height: 15px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  &-section-2 {
    margin-top: 20px;
    font-size: 0.85rem;
    color: $text;
  }

  &.empty {
    .add-to-cart-btn button {
      cursor: not-allowed;
    }
  }
}

.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paperWidthSm {
      &[aria-labelledby="responsive-dialog-title"] {
        min-width: 600px;
      }
    }
  }
}

@media (max-width: 800px) {
  .MuiDialog-root {
    .MuiDialog-container {
      .MuiDialog-paperWidthSm {
        &[aria-labelledby="responsive-dialog-title"] {
          min-width: unset;
        }
      }
    }
  }
}
