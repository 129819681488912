@import '../../../../../../css/styles/configs';

.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialogTitle-root {
      border-bottom: 2px solid #adc1cc;
    }
  }
}

.gt-new-user-dialog {
  margin-top: 12px;

  .success, .error {
    display: block;
    text-align: center;
    font-size: 15px;
    color: $text;
  }

  .error {
    color: $red;
  }

  .account-form {
    .gt-form-footer {
      margin-top: 45px;
      display: flex;

      .gt-button {
        margin: auto;
      }
    }
  }

  .dialog-action {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}