@import '../../../../../css/styles/configs';

.highlighted-products {
  &-carousel {
    .carousel-initialized {
      .carousel-track {
        .carousel-item {          
          .highlighted-products__product {
            position: relative;
            @include aspect-ratio(1, 1.2);

            &.full {
              @include aspect-ratio(1, 1.35);
            }
          }
        }
      }
    }
  }
}

.single-highlighted {
  .section-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0 20px;

    &-line {
      height: 1px;
      width: 20vw;
      min-width: 1px;
      background: $gray-dark;
      margin: 0 10px;
    }

    &-text {
      padding: 10px;
      font-size: 15px;
      color: $text;
      text-transform: uppercase;
    }
  }
}

.highlighted-product {
  display: flex;
  border: 1px solid #e6e6e6;

  &-details {
    width: 80%;
    background-color: $bg-gray-light;
    padding: 20px;
  }

  &-image {
    width: 20%;
    position: relative;
    border-left: 1px solid #e6e6e6;
    @include aspect-ratio(1, 1);

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      position: absolute;
      padding: 10px;
    }
  }

  &-header {
    margin: 0 0 5px;
    padding: 0 0 8px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 3px;
      bottom: -2px;
      background: $bg-blue-default;
      width: 130px;
    }

    h2 {
      font-family: $font-regular;
      color: $text;
      text-align: center;
      font-size: 1.2rem;
      margin-bottom: 0px;
    }
  }

  &-about {
    font-size: 0.9rem;
    line-height: 22px;
    letter-spacing: 0.3px;
    margin: 30px 0px 15px;
    color: #666;
    padding: 0px 5%;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-price {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
    align-items: center;
    line-height: 1.5rem;
    flex-wrap: wrap;

    .without-vat {
      color: $text-darkblue;
      font-size: 1.8rem;
      font-family: "GroteskMedium";
    }
    .with-vat {
      color: $old-price;
      font-size: 1rem;
      margin-left: 5px;
    }
  }

  &.highlight-two {
    margin-top: 10px;

    .highlighted-product-image {
      order: 1;
    }
    .highlighted-product-details {
      order: 2;
    }
  }
}