@import '../../../../../css/styles/configs';

@mixin slide ($url) {
  background-image: url($url), url('../../../../../image/slides/banner-0.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

$no-of-slides: 3;

.gt-slide-show {
  width: 100%;
  /*@include aspect-ratio(16, 8);*/

  .awssld {
    --slider-transition-duration: 700ms;
    --organic-arrow-thickness: 3px;
    --organic-arrow-border-radius: 30px;
    --organic-arrow-height: 25px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #4d8be2;
    --control-bullet-active-color: #216bb6;
    --loader-bar-color: #31a3e3;
    --loader-bar-height: 3px;
    --slider-height-percentage: 50%;
    --content-background-color: transparent;
  }

  .awssld__content {
    & > div:not(.awssld__timer) {
      height: 100%;
      width: 100%;
    }

    .slide {
      height: inherit;
      width: inherit;

      @for $i from 0 through ($no-of-slides - 1) {
        &.slide-no-#{$i} {
          /*@include slide('../../../../../image/slides/slide-#{$i}-1.jpg')*/
          @include slide('../../../../../image/slides/banner-#{$i}.jpg');
        }
      }
    }
  }

  .awssld__bullets {
    bottom: 10px;
    z-index: 2;

    &--active {
      border: 2px solid $white;
    }

    button {
      height: 12px;
      width: 12px;
    }
  }
}

@media (max-width: 500px) {
  .gt-slide-show {
    /*@include aspect-ratio(16, 8);*/

    .awssld {
      --slider-height-percentage: 40%;
    }
  }
}
