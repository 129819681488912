@import '../../../../../css/styles/configs';

.header-search {
  min-width: 600px;
  height: 50px;
  position: relative;
  border: 1px solid $gray-medium;
  border-radius: 50px;
  padding-left: 20px;
  background: #fff;
  @include flex-row-between;

  .gt-input {
    flex: 1;

    .simple-input {
      width: 100%;

      input {
        border: none;
        height: 48px;
        padding: 0 20px;
        border-radius: 10px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        border-left: 1px solid #ededed;
        outline: none;
        font-family: $font-regular;
        font-size: 13px;
      }
    }
  }

  .search-button {
    display: flex;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background: $bg-gray-light;
    right: 2px;
    top: 2px;
    border: none;
    margin-right: 3px;
    cursor: pointer;
    transition: all .3s ease-in-out;

    span {
      margin: auto;
    }

    &:hover {
      background: $bg-blue-default;
      span {
        color: $white;
      }
    }
  }
}

.mobile-search {
  .search-panel {
    position: absolute;
    top: 56px;
    left: -10px;
    width: 100vw;

    &-inner {
      @include flex-row-between;
      padding: 10px 30px;
      background: $white;
      border-bottom: 1px solid $gray-medium;

      .search-button {
        margin-right: 20px;
        span {
          font-size: 15px;
        }
      }

      .gt-input {
        flex: 1;

        .simple-input {
          width: 100%;
        }

        input {
          font-size: 13px;
        }
      }
    }
  }
}