// -----------------------------------------------------------------------------
// Basic template related styles
// -----------------------------------------------------------------------------

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

html, body {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
}

body {
  font-family: $font-regular;
  font-size: 13px;
  color: $black;
  font-weight: 400;
  line-height: 1.7;

  .body-wrapper {
    width: 100vw;
    height: 100vh;
    position: relative;

    .flex-row-btw {
      @include flex-row-between;
    }
  }
}

a, a:visited, a:active {
  text-decoration: none;
  color: $text;
}

ul {
  list-style-type: none;
}

p {
  margin: 0;
  padding: 0;
}

#gt-content {
  display: flex;
  flex-direction: column;
  /*background-color: #fafdff;*/
  background-color: #fcfdff;

  #gt-main-content {
    flex: 1;
    display: flex;
    min-height: 50vh;

    .page {
      min-height: 100%;
      padding: 15px 0 30px;
    }
  }

  #gt-footer {
    background-color: $bg-gray-light;
  }
}
