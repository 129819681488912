@import '../../../../../../css/styles/configs';

.gt-layout-switcher {
  display: flex;

  &.disabled {
    .icon-wrap {
      cursor: not-allowed;
      &.active {
        border: 1px solid $gray-dark;
        background-color: $gray-dark;
      }
    }
  }

  .icon-wrap {
    background-color: transparent;
    border: 1px solid $gray-dark;
    transition: all .3s linear;
    border-radius: 2px;
    width: 28px;
    height: 28px;
    display: flex;
    cursor: pointer;

    .gt-icon {
      margin: auto;
      font-size: 15px;
      outline: none;
      color: $text;
    }

    &:last-child {
      margin-left: 8px;
    }

    &.active {
      border: 1px solid $text-blue;
      background-color: $text-blue;
      .gt-icon {
        color: $white;
      }
    }
  }
}