@import '../../../../../css/styles/configs';

.gt-product-tags {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;

  &-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;    
  }

  &-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;    
  }

  span {
    display: block;
    color: $white;
    text-transform: uppercase;
    font-size: .6rem;
    font-weight: 700;
    line-height: 22px;
    padding: 0 5px;
    min-width: 50px;
    font-family: $font-semibold;

    &.favorite {
      background-color: $label-favorite;
    }

    &.special {
      background-color: $label-special;
      width: 180px;
      height: auto;
      line-height: 25px;
      margin-top: 0;
      padding: 41px 0 0px;
      position: absolute;
      top: -28px;
      left: -86px;
      transform: rotate(-45deg);
      text-align: center;
      font-size: .6rem;
    }
  }
}