@import '../../../../../css/styles/configs';

.gt-page-header {
  margin: 10px 0 30px;

  &-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-title {
      color: $text;
      font-size: 16px;
    }

    &-options {
      display: flex;
      align-items: center;

      &.disabled {
        opacity: 0.5;

        .options-toggler {
          cursor: not-allowed;
        }
      }

      .options-toggler {
        background-color: transparent;
        border: 1px solid $gray-dark;
        transition: all .3s linear;
        border-radius: 2px;
        cursor: pointer;
        height: 28px;
        padding: 0 10px;
        display: flex;
        align-items: center;

        &-label {
          margin-left: 5px;
          font-size: 14px;
        }

        .gt-icon {
          font-size: 15px;
        }

        &.active {
          border: 1px solid $text-blue;
          background-color: $text-blue;

          span {
            color: $white;
          }
        }
      }

      .gt-vertical-separator {
        height: 25px;
        width: 1px;
        min-width: 1px;
        background: $gray-dark;
        margin: 0 10px;
      }
    }
  }

  &-options {
    &-list {
      margin: 20px 0 0;
      padding: 10px;
      /*background-color: #efefef; /* $bg-gray-light; */
      background-color: $white;
      @include boxshadow;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 40px;
    }

    &__option {
      min-width: 200px;

      .option-label {
        display: block;
        font-size: 13px;
        text-transform: uppercase;
        font-family: $font-semibold;
        color: $text-blue;
        border-bottom: 1px solid #b6b6b6;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 650px) {
  .gt-page-header {
    &-main {
      &-options {
        .options-toggler {
          &-label {
            display: none;
          }
        }
      }
    }

    &-options {
      &-list {
        grid-gap: 20px;
      }
    }
  }
}
