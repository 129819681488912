@import '../../../../../css/styles/configs';

@mixin cart-product {
  .product {
    margin-bottom: 8px;
    overflow: hidden;
    padding: 8px 0 15px;
    width: 272px;
    margin: 5px 0px;
    min-height: 67px;
    @include flex-row-between;
    align-items: flex-start;
    position: relative;

    .gt-loader.product-loader {
      @include position-center;

      .rect {
        width: 2px;
      }
    }

    &.changing {
      .product-image, .product-details, .product-remove {
        opacity: .2;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray-medium;
    }

    &-image {
      display: block;
      width: 68px;
      height: 68px;
      padding: 5px;
      border: 1px $gray-medium solid;

      img {
        border: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &-details {
      flex: 1;
      margin: 0 10px;

      &__name {
        display: block;
        font-size: 13px;
        line-height: 18px;
        color: $text;
        transition: all .3s linear;

        &:hover {
          color: $text-blue;
        }
      }

      &__amounts {
        span {
          &:not(:nth-child(2)) {
            font-family: $font-semibold;
          }
          &:nth-child(2) {
            margin: 0 5px;
          }
        }
      }
    }

    &-remove {
      cursor: pointer;
      font-size: 14px;
      color: $text;

      &:hover {
        color: $red;
      }
    }
  }
}

@mixin cart-subtotal ($font-size) {
  &-subtotal {
    padding: 8px 15px;
    background-color: $bg-gray-light;
    margin-bottom: 12px;
    @include flex-row-between;

    span {
      font-family: $font-semibold;
      font-size: $font-size;
      font-weight: bold;
      color: $text;
      text-align: left;
    }
  }
}

@mixin cart-actions ($paddings) {
  &-actions {
    padding: $paddings;
    margin: 0;
    @include flex-row-between;

    .gt-button {
      flex: 1;

      button {
        width: 100%;
      }

      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
}

@mixin cart-items-counter ($top, $right, $dimensions, $border: none) {
  .mini-cart__items {
    position: absolute;
    display: block;
    top: $top;
    right: $right;
    background: $bg-blue-default;
    border-radius: 50%;
    color: $white;
    line-height: 18px;
    height: $dimensions;
    width: $dimensions;
    text-align: center;
    font-size: 10px;
    border: $border;

    &.loading {
      display: flex;
      .gt-loader {
        margin: auto;
        .spinner {
          width: 13px;
          height: 13px;
          .circle:before {
            background-color: $white;
          }
        }
      }
    }
  }
}

.gt-mini-cart {

  &.mini-cart-web {
    position: relative;

    .mini-cart {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &__icon {
        position: relative;

        .gt-icon {
          font-size: 23px;
        }

        @include cart-items-counter(-9px, -14px, 18px);
        /*@include cart-items-loader(-9px, -14px, 15px);*/
      }
    }
  }

  &.mini-cart-mobile {
    .mini-cart {
      &__icon {
        position: relative;

        @include cart-items-counter(-7px, -7px, 22px, 2px solid $white);
        /*@include cart-items-loader(-7px, -7px, 15px);*/
      }
    }
  }

  /* Panel on web view  */
  .mini-cart-panel {
    position: absolute;
    top: 55px;
    right: 0;
    width: 310px;
    z-index: 100;

    .mini-cart__contents {
      background: $white;
      box-shadow: 0 0 3px 0 $bg-gray-light;
      border: 1px $gray-medium solid;

      &-empty,
      &-no-user {
        padding: 15px;
        text-align: center;
      }

      &-list {
        padding: 3px 15px 6px;
        margin: auto;
        max-height: 195px;

        @include cart-product;
      }

      @include cart-subtotal(14px);

      @include cart-actions(5px 15px 20px);
    }
  }
}

/* Panel on mobile view  */
.mobile-cart {
  width: 310px;
  display: flex;
  flex-direction: column;
  height: 100vh;

  &-topbar {
    height: 70px;
    min-height: 70px;
    border-bottom: 1px solid $gray-medium;
    padding: 0 20px;
    @include flex-row-between;

    &__title {
      font-size: 15px;
    }
    .gt-icon {
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: $text-blue;
      }
    }
  }

  &-contents {
    flex: 1;
    max-height: calc(100vh - 70px);
    padding: 15px;
    display: flex;
    flex-direction: column;

    &-empty,
    &-no-user {
      text-align: center;
    }

    &-list {
      padding: 3px 0px 6px;
      height: auto;

      @include cart-product;
    }

    &-bottom {
      margin-top: 15px;
    }

    @include cart-subtotal(13px);

    @include cart-actions(5px 0px 0px);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .gt-mini-cart {
    &.mini-cart-mobile {
      .mini-cart__icon {
        .mini-cart__items {
          top: -10px;
          right: -10px;
          height: 20px;
          width: 20px;
          border: 1px solid $white;
        }
      }
    }
  }

  .mobile-cart {
    &-topbar {
      height: 61px;
      min-height: 61px;
    }
    &-contents {
      max-height: calc(100vh - 61px);
    }
  }
}
