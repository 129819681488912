// -----------------------------------------------------------------------------
// This file contains Icon Fonts
// -----------------------------------------------------------------------------

// Icon font Config, Copy it from IcoMoon Variable.scss and covert it to this format
// -----------------------------------------------------------------------------
$fontCharacters:
"gt-icon-facebook" "\e900",
"gt-icon-twitter" "\e901",
"gt-icon-instagram" "\e902",
"gt-icon-linkedin" "\e903",
"gt-icon-pinterest" "\e904",
"gt-icon-users" "\e905",
"gt-icon-arrow-down" "\e906",
"gt-icon-arrow-up" "\e907",
"gt-icon-arrow-right" "\e912",
"gt-icon-success" "\e908",
"gt-icon-close-circle" "\e909",
"gt-icon-error" "\e90a",
"gt-icon-circle-arrow-down" "\e90b",
"gt-icon-circle-arrow-up" "\e90c",
"gt-icon-up-arrow" "\e90d",
"gt-icon-down-arrow" "\e90e",
"gt-icon-left-arrow" "\e90f",
"gt-icon-right-arrow" "\e910",
"gt-icon-upload" "\e911",
"gt-icon-user" "\e913",
"gt-icon-shopping-bag" "\e914",
"gt-icon-menu" "\e915",
"gt-icon-search" "\e916",
"gt-icon-delete" "\e917",
"gt-icon-heart" "\e918",
"gt-icon-prev" "\e919",
"gt-icon-next" "\e91a",
"gt-icon-eye" "\e91b",
"gt-icon-info" "\e91c",
"gt-icon-warning" "\e91d",
"gt-icon-add-to-cart" "\e91e",
"gt-icon-grid" "\e91f",
"gt-icon-list" "\e920",
"gt-icon-filter" "\e921",
"gt-icon-plus" "\e922",
"gt-icon-minus" "\e923",
"gt-icon-broken-heart" "\e924",
"gt-icon-instructions" "\e925",
"gt-icon-contents" "\e926",
"gt-icon-phone" "\e927",
"gt-icon-mobile" "\e928",
"gt-icon-mail" "\e929",
"gt-icon-clock" "\e92a",
"gt-icon-pin" "\e92b",
"gt-icon-tradition" "\e92c",
"gt-icon-products" "\e92d",
"gt-icon-brands" "\e92e",
"gt-icon-clients" "\e92f",
"gt-icon-shipping" "\e930",
"gt-icon-discount" "\e931",
"gt-icon-support" "\e932",
"gt-icon-map" "\e933",
"gt-icon-edit" "\e934",
"gt-icon-download" "\e935";

.icon-shipping:before {
  content: "\e930";
}
.icon-discount:before {
  content: "\e931";
}
.icon-support:before {
  content: "\e932";
}
.icon-map:before {
  content: "\e933";
}

// Icon font Code
// -----------------------------------------------------------------------------
%ico-common {
  font-family: 'gt' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gt-icon {
  font-size: 12px;
  &.disabled {
    cursor: not-allowed;
    color: #000000;
  }
}

@each $i in $fontCharacters {
  .#{nth($i, 1)}:before,
  .#{nth($i, 1)} {
    @extend %ico-common;
    content: nth($i, 2);
  }
}
