@import '../../../../../../../css/styles/configs';

.product-files {
  position: relative;

  .uploader {
    position: absolute;
    right: 0;
    top: 4px;
  }

  .attachments {
    padding-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
  }

  .empty {
    color: $text;
    padding: 10px 0;
    display: block;
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
}