@import '../../../../../../css/styles/configs';

.MuiDialogTitle-root {
  .MuiTypography-root {
    &.MuiTypography-h6 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .gt-dialog-title {
        font-size: 1rem;
        line-height: 1.2rem;
      }
      .gt-icon {
        font-size: 23px;
        cursor: pointer;

        &:hover {
          color: $text-blue;
        }
      }
    }
  }
}

.MuiDialog-container {
  .MuiPickersDatePickerRoot-toolbar {
    background-color: $text-blue;
  }
  .MuiPickersDay-daySelected {
    background-color: $text-blue;

    &:hover {
      background-color: $text-darkblue;
    }
  }
}
