@import '../../../../../css/styles/configs';

.web-header {
  border-bottom: 1px solid $gray-light;

  .topbar {
    height: 35px;
    background: $bg-gray-light;
    border-bottom: 1px solid $gray-medium;

    .topbar-inner {
      @include flex-row-between;

      p {
        line-height: 35px;

        .greeting {
          color: $text-darkblue;
          font-family: $font-semibold;
        }
      }

      &-menu,
      &-menu .topbar-menu,
      &-menu .topbar-social-links, {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &-menu {
        .topbar-menu {
          margin: 0 5px 0;
          padding-right: 10px;
          border-right: 1px solid $gray-dark;

          ul li a {
            color: $black;
            &:hover {
              color: $text-blue;
            };
          }
        }

        .topbar-social-links {
          a, a:visited, a:active {
            line-height: 33px;
            color: $text;
            font-size: 13px;
            margin: 0 5px;

            &:hover {
              color: $text-blue;
            }
          }
        }
      }
    }
  }

  .main-header {
    .header-logo {
      width: 16.7%;
      line-height: 87px;
      padding-right: 15px;

      .logo {
        display: block;
        height: auto;
        max-width: 160px;
        font-size: 0px;
        cursor: pointer;
        @include background-setup('../../../../../image/logo.png', left);
      }
    }
    .header-search {
      width: 66.7%;
      min-height: 1px;
    }
    .header-user-utilities {
      width: 16.7%;
      padding-left: 15px;
      display: flex;
      justify-content: flex-end;

      .hu-item {
        &:hover {
          .gt-icon {
            &-heart, &-shopping-bag {
              color: $text-blue;
            }
          }
        }

        &:not(:last-child) {
          margin-right: 1.8vw;
        }
      }
    }
  }

  .main-menu {
    background-color: $bg-blue-default;

    &-inner {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .main-nav-bar {
        display: flex;
        justify-content: flex-start;
        list-style: none;

        li a {
          display: block;
          position: relative;
          font-size: 15px;
          color: $white;
          padding: 10px 15px;
          letter-spacing: 0px;
          text-decoration: none;
          transition: all .3s linear;
          z-index: 10;

          &:before {
            content: "";
            position: absolute;
            left: 0px;
            bottom: 0px;
            height: 100%;
            width: 0px;
            z-index: -1;
            background: #0077b2;
            transition: 0.2s ease-in-out;
          }

          &:hover {
            &:before {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.mobile-header {
  padding: 15px 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  z-index: 97;
  border-bottom: 1px solid $gray-light;
  /*box-shadow: 0 2px 10px $gray-light;*/
  transition: all .4s ease-in-out 0s;

  .header-inner {
    position: relative;
  }

  .header-logo {
    flex: 1;
    line-height: 40px;
    padding: 0 15px;

    .logo {
      display: block;
      height: auto;
      max-width: 160px;
      font-size: 0px;
      cursor: pointer;
      @include background-setup('../../../../../image/logo.png', left);
    }
  }

  .menu-item {
    margin-left: 12px;
    width: 40px;
    height: 40px;
    border: 1px solid $gray-medium;
    background-color: $white;
    cursor: pointer;
    @include transition(all 0.3s ease-out);
    display: flex;

    &.box-item:hover, .box-item:hover,
    &.box-item:active, .box-item:active {
      background-color: $bg-blue-default;

      span {
        color: $white;
      }
    }
    
    div.icon-wrapper {
      display: flex;
      width: 100%;
    }

    span {
      margin: auto;
      font-size: 20px;
    }

    &:last-child {
      /*margin-right: 25px;*/
    };
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .web-header {
    display: none;
  }

  .mobile-header {
    padding: 10px;

    .header-logo {
      order: 2;

      .logo {
        max-width: initial;
        background-position: center;
      }
    }
    .menu-item {
      margin: 0;
      width: 30px;
      height: 30px;

      &:last-child {
        margin: 0;
      }
      &.menu-categories {
        order: 1;
        margin-left: 5px;
      }
      &.user-page,
      &.search-store {
        display: none;
      }
    }
    .mini-cart-mobile {
      order: 3;
      margin-right: 5px;
    }
  }
}

/* Small devices (portrait tablets and large phones) */
@media (min-width: 600px) and (max-width: 1024px) {
  .web-header {
    display: none;
  }
}

/* Desktop screen sizes */
@media only screen and (min-width: 1025px) {
  .mobile-header {
    display: none;
  }
}