@import '../../../../../css/styles/configs';

.gt-section-header {
  .header-title {
    margin: 0 0 5px;
    padding: 0 0 8px;
    border-bottom: 1px solid #e5e5e5;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      height: 3px;
      bottom: -2px;
      background: $bg-blue-default;
      width: 130px;
    }

    h2 {
      font-family: $font-regular;
      color: $text;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 0px;
    }
  }
}
