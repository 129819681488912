@import '../../../../../css/styles/configs';

.main-about {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;

  & > div {
    background: #fff;
    border: 1px #e8e8e8 solid;
    color: #333;
    padding: 15px;
    border-radius: 3px;
    display: flex;

    .inner {
      display: flex;
      align-items: center;
      margin: auto;

      .gt-icon {
        font-size: 40px;
        margin-right: 20px;
        color: $text;
      }

      &-text {
        h3 {
          font-size: 1.1rem;
          line-height: 22px;
          font-family: $font-semibold;
          color: $text-darkblue;
          margin-bottom: 3px;
        }
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 1000px) {
  .main-about {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 10px;
  }
}
