@charset "UTF-8";
@font-face {
  font-family: 'OpenSansRegular';
  src: url("../fonts/OpenSans/OpenSans-Regular-webfont.woff") format("woff"), url("../fonts/OpenSans/OpenSans-Regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSansLight';
  src: url("../fonts/OpenSans/OpenSans-Light-webfont.woff") format("woff"), url("../fonts/OpenSans/OpenSans-Light-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSansSemibold';
  src: url("../fonts/OpenSans/OpenSans-Semibold-webfont.woff") format("woff"), url("../fonts/OpenSans/OpenSans-Semibold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'OpenSansBold';
  src: url("../fonts/OpenSans/OpenSans-Bold-webfont.woff") format("woff"), url("../fonts/OpenSans/OpenSans-Bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'gt';
  src: url("../fonts/GtIcons/gticons.woff?o8c89j") format("woff"), url("../fonts/GtIcons/gticons.ttf?o8c89j") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GroteskRegular';
  src: url("../fonts/Grotesk/hkgrotesk-regular-webfont.woff2") format("woff2"), url("../fonts/Grotesk/hkgrotesk-regular-webfont.woff") format("woff"), url("../fonts/Grotesk/hkgrotesk-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GroteskBold';
  src: url("../fonts/Grotesk/hkgrotesk-bold-webfont.woff2") format("woff2"), url("../fonts/Grotesk/hkgrotesk-bold-webfont.woff") format("woff"), url("../fonts/Grotesk/hkgrotesk-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GroteskLight';
  src: url("../fonts/Grotesk/hkgrotesk-light-webfont.woff2") format("woff2"), url("../fonts/Grotesk/hkgrotesk-light-webfont.woff") format("woff"), url("../fonts/Grotesk/hkgrotesk-light-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'GroteskMedium';
  src: url("../fonts/Grotesk/hkgrotesk-medium-webfont.woff2") format("woff2"), url("../fonts/Grotesk/hkgrotesk-medium-webfont.woff") format("woff"), url("../fonts/Grotesk/hkgrotesk-medium-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none; }

html, body {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff; }

body {
  font-family: "GroteskRegular";
  font-size: 13px;
  color: #000000;
  font-weight: 400;
  line-height: 1.7; }
  body .body-wrapper {
    width: 100vw;
    height: 100vh;
    position: relative; }
    body .body-wrapper .flex-row-btw {
      display: flex;
      align-items: center;
      justify-content: space-between; }

a, a:visited, a:active {
  text-decoration: none;
  color: #555555; }

ul {
  list-style-type: none; }

p {
  margin: 0;
  padding: 0; }

#gt-content {
  display: flex;
  flex-direction: column;
  /*background-color: #fafdff;*/
  background-color: #fcfdff; }
  #gt-content #gt-main-content {
    flex: 1;
    display: flex;
    min-height: 50vh; }
    #gt-content #gt-main-content .page {
      min-height: 100%;
      padding: 15px 0 30px; }
  #gt-content #gt-footer {
    background-color: #f8f8f8; }

.icon-shipping:before {
  content: "\e930"; }

.icon-discount:before {
  content: "\e931"; }

.icon-support:before {
  content: "\e932"; }

.icon-map:before {
  content: "\e933"; }

.gt-icon-facebook:before,
.gt-icon-facebook, .gt-icon-twitter:before,
.gt-icon-twitter, .gt-icon-instagram:before,
.gt-icon-instagram, .gt-icon-linkedin:before,
.gt-icon-linkedin, .gt-icon-pinterest:before,
.gt-icon-pinterest, .gt-icon-users:before,
.gt-icon-users, .gt-icon-arrow-down:before,
.gt-icon-arrow-down, .gt-icon-arrow-up:before,
.gt-icon-arrow-up, .gt-icon-arrow-right:before,
.gt-icon-arrow-right, .gt-icon-success:before,
.gt-icon-success, .gt-icon-close-circle:before,
.gt-icon-close-circle, .gt-icon-error:before,
.gt-icon-error, .gt-icon-circle-arrow-down:before,
.gt-icon-circle-arrow-down, .gt-icon-circle-arrow-up:before,
.gt-icon-circle-arrow-up, .gt-icon-up-arrow:before,
.gt-icon-up-arrow, .gt-icon-down-arrow:before,
.gt-icon-down-arrow, .gt-icon-left-arrow:before,
.gt-icon-left-arrow, .gt-icon-right-arrow:before,
.gt-icon-right-arrow, .gt-icon-upload:before,
.gt-icon-upload, .gt-icon-user:before,
.gt-icon-user, .gt-icon-shopping-bag:before,
.gt-icon-shopping-bag, .gt-icon-menu:before,
.gt-icon-menu, .gt-icon-search:before,
.gt-icon-search, .gt-icon-delete:before,
.gt-icon-delete, .gt-icon-heart:before,
.gt-icon-heart, .gt-icon-prev:before,
.gt-icon-prev, .gt-icon-next:before,
.gt-icon-next, .gt-icon-eye:before,
.gt-icon-eye, .gt-icon-info:before,
.gt-icon-info, .gt-icon-warning:before,
.gt-icon-warning, .gt-icon-add-to-cart:before,
.gt-icon-add-to-cart, .gt-icon-grid:before,
.gt-icon-grid, .gt-icon-list:before,
.gt-icon-list, .gt-icon-filter:before,
.gt-icon-filter, .gt-icon-plus:before,
.gt-icon-plus, .gt-icon-minus:before,
.gt-icon-minus, .gt-icon-broken-heart:before,
.gt-icon-broken-heart, .gt-icon-instructions:before,
.gt-icon-instructions, .gt-icon-contents:before,
.gt-icon-contents, .gt-icon-phone:before,
.gt-icon-phone, .gt-icon-mobile:before,
.gt-icon-mobile, .gt-icon-mail:before,
.gt-icon-mail, .gt-icon-clock:before,
.gt-icon-clock, .gt-icon-pin:before,
.gt-icon-pin, .gt-icon-tradition:before,
.gt-icon-tradition, .gt-icon-products:before,
.gt-icon-products, .gt-icon-brands:before,
.gt-icon-brands, .gt-icon-clients:before,
.gt-icon-clients, .gt-icon-shipping:before,
.gt-icon-shipping, .gt-icon-discount:before,
.gt-icon-discount, .gt-icon-support:before,
.gt-icon-support, .gt-icon-map:before,
.gt-icon-map, .gt-icon-edit:before,
.gt-icon-edit, .gt-icon-download:before,
.gt-icon-download {
  font-family: 'gt' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.gt-icon {
  font-size: 12px; }
  .gt-icon.disabled {
    cursor: not-allowed;
    color: #000000; }

.gt-icon-facebook:before,
.gt-icon-facebook {
  content: ""; }

.gt-icon-twitter:before,
.gt-icon-twitter {
  content: ""; }

.gt-icon-instagram:before,
.gt-icon-instagram {
  content: ""; }

.gt-icon-linkedin:before,
.gt-icon-linkedin {
  content: ""; }

.gt-icon-pinterest:before,
.gt-icon-pinterest {
  content: ""; }

.gt-icon-users:before,
.gt-icon-users {
  content: ""; }

.gt-icon-arrow-down:before,
.gt-icon-arrow-down {
  content: ""; }

.gt-icon-arrow-up:before,
.gt-icon-arrow-up {
  content: ""; }

.gt-icon-arrow-right:before,
.gt-icon-arrow-right {
  content: ""; }

.gt-icon-success:before,
.gt-icon-success {
  content: ""; }

.gt-icon-close-circle:before,
.gt-icon-close-circle {
  content: ""; }

.gt-icon-error:before,
.gt-icon-error {
  content: ""; }

.gt-icon-circle-arrow-down:before,
.gt-icon-circle-arrow-down {
  content: ""; }

.gt-icon-circle-arrow-up:before,
.gt-icon-circle-arrow-up {
  content: ""; }

.gt-icon-up-arrow:before,
.gt-icon-up-arrow {
  content: ""; }

.gt-icon-down-arrow:before,
.gt-icon-down-arrow {
  content: ""; }

.gt-icon-left-arrow:before,
.gt-icon-left-arrow {
  content: ""; }

.gt-icon-right-arrow:before,
.gt-icon-right-arrow {
  content: ""; }

.gt-icon-upload:before,
.gt-icon-upload {
  content: ""; }

.gt-icon-user:before,
.gt-icon-user {
  content: ""; }

.gt-icon-shopping-bag:before,
.gt-icon-shopping-bag {
  content: ""; }

.gt-icon-menu:before,
.gt-icon-menu {
  content: ""; }

.gt-icon-search:before,
.gt-icon-search {
  content: ""; }

.gt-icon-delete:before,
.gt-icon-delete {
  content: ""; }

.gt-icon-heart:before,
.gt-icon-heart {
  content: ""; }

.gt-icon-prev:before,
.gt-icon-prev {
  content: ""; }

.gt-icon-next:before,
.gt-icon-next {
  content: ""; }

.gt-icon-eye:before,
.gt-icon-eye {
  content: ""; }

.gt-icon-info:before,
.gt-icon-info {
  content: ""; }

.gt-icon-warning:before,
.gt-icon-warning {
  content: ""; }

.gt-icon-add-to-cart:before,
.gt-icon-add-to-cart {
  content: ""; }

.gt-icon-grid:before,
.gt-icon-grid {
  content: ""; }

.gt-icon-list:before,
.gt-icon-list {
  content: ""; }

.gt-icon-filter:before,
.gt-icon-filter {
  content: ""; }

.gt-icon-plus:before,
.gt-icon-plus {
  content: ""; }

.gt-icon-minus:before,
.gt-icon-minus {
  content: ""; }

.gt-icon-broken-heart:before,
.gt-icon-broken-heart {
  content: ""; }

.gt-icon-instructions:before,
.gt-icon-instructions {
  content: ""; }

.gt-icon-contents:before,
.gt-icon-contents {
  content: ""; }

.gt-icon-phone:before,
.gt-icon-phone {
  content: ""; }

.gt-icon-mobile:before,
.gt-icon-mobile {
  content: ""; }

.gt-icon-mail:before,
.gt-icon-mail {
  content: ""; }

.gt-icon-clock:before,
.gt-icon-clock {
  content: ""; }

.gt-icon-pin:before,
.gt-icon-pin {
  content: ""; }

.gt-icon-tradition:before,
.gt-icon-tradition {
  content: ""; }

.gt-icon-products:before,
.gt-icon-products {
  content: ""; }

.gt-icon-brands:before,
.gt-icon-brands {
  content: ""; }

.gt-icon-clients:before,
.gt-icon-clients {
  content: ""; }

.gt-icon-shipping:before,
.gt-icon-shipping {
  content: ""; }

.gt-icon-discount:before,
.gt-icon-discount {
  content: ""; }

.gt-icon-support:before,
.gt-icon-support {
  content: ""; }

.gt-icon-map:before,
.gt-icon-map {
  content: ""; }

.gt-icon-edit:before,
.gt-icon-edit {
  content: ""; }

.gt-icon-download:before,
.gt-icon-download {
  content: ""; }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #gt-header {
    min-height: 60px; }
  #gt-content {
    min-height: calc(100vh - 60px); } }

/* Small devices (portrait tablets and large phones) */
@media (min-width: 601px) and (max-width: 1024px) {
  #gt-header {
    min-height: 70px; }
  #gt-content {
    min-height: calc(100vh - 70px); } }

/* Desktop screen sizes */
@media only screen and (min-width: 1025px) {
  #gt-header {
    min-height: 168px; }
  #gt-content {
    min-height: calc(100vh - 168px); } }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%; }

@media (min-width: 360px) {
  .container {
    width: 100%;
    padding: 0 10px; } }

@media (min-width: 768px) {
  .container {
    width: 100%;
    padding: 0 10px; } }

@media (min-width: 992px) {
  .container {
    width: 100%;
    padding: 0 10px; } }

@media (min-width: 1024px) {
  .container {
    width: 970px; } }

@media (min-width: 1200px) {
  .container {
    width: 1200px; } }

/*@media (min-width: 1400px) {
  .container {
    width: 1450px;
  }
}

@media (min-width: 1620px) {
  .container {
    width: 1650px;
  }
}*/
