@import '../../../../../../css/styles/configs';

.gt-carousel-product {
  color: $text;
  font-size: 13px;
  padding: 0px 10px;
  margin-top: 17px;
  width: 100%;
  height: 100%;

  &-contents {
    border: 1px solid #e6e6e6;
    padding: 9px;
    background: $white;
    width: 100%;
    position: relative;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all .3s linear;

    &:hover {
      box-shadow: 0 2px 6px 0 #e0e4e7, inset -1px 0 0 0 rgba(224, 227, 231, .3), inset 0 -1px 0 0 #e0e4e7, inset 1px 0 0 0 rgba(224, 227, 231, .2);
    }

    &-body {
      .img-wrapper {
        position: relative;
        border: 1px solid $gray-light;
        overflow: hidden;
        @include aspect-ratio(1, 1);

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          position: absolute;
          padding: 10px;
        }
      }

      .line-item {
        line-height: 18px;
        font-size: 14px;
        display: block;

        &.name {
          color: $text;
          font-family: $font-semibold;
          font-weight: 600;
          min-height: 35px;
          max-height: 35px;
          cursor: pointer;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 5px 0 2px;
          text-align: center;

          &:hover {
            color: $text-blue;
          }
        }
      }
    }

    &-footer {
      position: relative;

      .price {
        font-size: 12px;
        padding: 5px 0 15px;
        text-align: center;

        .display-price {
          font-family: $font-semibold;
          color: $text-darkblue;
          font-size: 17px;
        }

        .old-price {
          color: $old-price;
          text-decoration: line-through;
          font-weight: normal;
          margin-left: 10px;
        }
      }
    }
  }

  &.dummy {
    position: absolute;
    height: 98%;

    .gt-carousel-product-contents {
      display: flex;

      .gt-product-loading {
        margin: auto;

        .gt-loader .stretchingBars .rect {
          width: 2px;
        }
      }
    }
  }
}