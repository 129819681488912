// -----------------------------------------------------------------------------
// Font variations
// -----------------------------------------------------------------------------

// Color variables
$white: #ffffff;
$black: #000000;
$red: #e83f33;
$green: #53a653;
$orange: #e88e33;
$text: #555555;
$text-blue: #0088cc;
$text-darkblue: #03689a;
$price: #27800b;
$old-price: #b5b5b5;

// Border color variables
$gray-light: #f5f5f5;
$gray-medium: #ebebeb;
$gray-dark: #d8d8d8;
$gray-darker: #bdbdbd;
$border-blue: #65b0d6;
$new-border: #ccdde6;

// Labels color variables
$label-favorite: #53a653;
$label-special: #00bcd4;

// Background color variables
$bg-gray-light: #f8f8f8;
$bg-blue-default: #0088cc;
$bg-blue-dark: #03689a;

// Font variables
$font-regular: 'GroteskRegular';
$font-light: 'GroteskLight';
$font-semibold: 'GroteskMedium';
$font-bold: 'GroteskBold';

// Font Sizes