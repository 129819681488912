@import '../../../../../css/styles/configs';

.admin-page {
  display: flex;
  flex-direction: column;

  &-section-1 {
    margin: 0 0 20px 0;
    padding: 10px;
    padding-bottom: 0;
    background-color: #ffffff;
    @include boxshadow;
    display: flex;

    .utils-btns {
      margin: auto;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
      grid-gap: 40px;

      .util-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        position: relative;
        color: $text;

        &:hover {
          color: $text-blue;
        }

        .gt-icon {
          font-size: 25px;
        }

        .util-btn-label {
          position: relative;

          &:after {
            content: '';
            position: relative;
            display: block;
            width: 0;
            height: 2px;
            margin-top: 6px;
            font-weight: 600;
            background-color: #08c;
            left: 50%;
            transition: all 0.3s ease-in;
          }
        }

        &.active {
          .util-btn-label:after {
            left: 0;
            width: 100%;
          }
        }
      }
    }
  }

  &-section-2 {
    flex: 1;
    min-height: 200px;
    position: relative;

    .gt-loader {
      &.admin-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .util {
      &.loading {
        display: none;
      }
    }
  }

  &-greeting {
    height: 200px;
    display: flex;

    span {
      margin: auto;
    }
  }
}