@import '../../../../../css/styles/configs';

.about-page {
  &-section-1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    grid-gap: 50px;
    margin-top: 20px;

    .about-text {
      span {
        display: block;
        color: $text;
        font-size: 0.9rem;
        line-height: 1.5rem;
        text-align: justify;
      }

      &-paragraph {
        &.intro {
          font-family: $font-regular;
          font-size: 22px;
          color: $text-blue;
        }
        &:not(.intro) {
          margin-top: 15px;
        }
      }
    }
    .about-logo {
      min-height: 200px;
      @include background-setup('../../../../../image/logo-with-drops.png', center);
    }
  }

  &-section-2 {
    margin: 40px 0;
    padding-bottom: 40px;
    margin-top: 45px;
    padding-top: 35px;
    background-color: $bg-gray-light;
    display: flex;
    @include boxshadow;

    .section-box {
      padding: 0 15px;
      flex: 1;

      span {
        display: block;
        text-align: center;
      }

      &-header {
        color: $text-darkblue;
        font-size: 18px;
        font-weight: 600;
        font-family: $font-semibold;
        margin: 0 0 5px 8px;
        line-height: 1.35;
      }

      &-description {
        color: $text;
        line-height: 1.3rem;
        font-size: 0.8rem;
      }

      .gt-icon {
        font-size: 50px;
        color: #333333;
        margin-bottom: 10px;
      }
    }
  }

  &-section-3 {
    margin-bottom: 50px;

    .section-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 60px 0 30px;

      &-line {
        height: 1px;
        width: 20vw;
        min-width: 1px;
        background: $gray-dark;
        margin: 0 10px;
      }

      &-text {
        padding: 10px;
        font-size: 15px;
        color: $text;
        text-transform: uppercase;
      }
    }

    .section-downloads {
      display: flex;

      .download-items {
        margin: auto;
        display: flex;
        flex-wrap: wrap;

        .item {
          display: block;
          width: 40vw;
          max-width: 330px;
          margin: 0 20px;
          @include aspect-ratio(5, 7);

          &.certificate-iso-9001 {
            @include background-setup('../../../../../image/iso9001.jpg', center);
          }
          &.certificate-iso-14001 {
            @include background-setup('../../../../../image/iso14001.jpg', center);
          }
          &.company-profile {
            @include background-setup('../../../../../image/company_profile.jpg', center);
          }
          &.product-catalogue {
            @include background-setup('../../../../../image/product_catalogue.jpg', center);
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .about-page {
    &-section-3 {
      .section-downloads {
        .download-items a {
          margin: 0 auto;

          &:not(:first-child) {
            margin-top: 20px;
          }

          .item {
            width: 330px;
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .about-page {
    &-section-2 {
      flex-direction: column;

      .section-box{
        &:not(:first-child) {
          margin-top: 30px;
        }
        &-description {
          font-size: 0.9rem;
        }
      }
    }
  }
}
