@import '../../../../../css/styles/configs';

.gt-quantity {
  @include flex-row-between;
  justify-content: center;

  .gt-input {
    margin: 0 5px;
    max-width: 75px;
  }

  &-control, .gt-button {
    .MuiButtonBase-root {
      padding: 10px 6px;
      min-width: 40px;
      background-color: $white;
      color: $gray-darker;
      border: 1px solid $gray-darker;
      border-radius: 5px;

      &.MuiButton-contained {
        background-color: $white;
      }

      .gt-icon {
        font-size: 15px;
      }

      &.Mui-disabled .gt-icon {
        color: $text;
      }
    }
  }
}
