@import '../../../../../css/styles/configs';

.mini-favorites {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &__icon {
    position: relative;

    .gt-icon {
      font-size: 23px;
    }
    .mini-favorites__items {
      position: absolute;
      display: block;
      top: -9px;
      right: -17px;
      background: $bg-blue-default;
      border-radius: 50%;
      color: $white;
      line-height: 18px;
      height: 18px;
      width: 18px;
      text-align: center;
      font-size: 10px;

      &.loading {
        display: flex;
        .gt-loader {
          margin: auto;
          .spinner {
            width: 13px;
            height: 13px;
            .circle:before {
              background-color: $white;
            }
          }
        }
      }
    }
  }
}
