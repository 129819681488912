@import '../../../../../css/styles/configs';

.gt-delivery-dates {
  .gt-fieldset {
    height: 100%;

    .MuiTextField-root {
      height: 100%;

      .MuiInputBase-root {
        height: 100%;

        .gt-radio-group {
          .gt-radio {
            .MuiTypography-body1 {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}