@import '../../../../../../css/styles/configs';

.gt-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;

  &__image {
    display: block;
    width: 210px;
    height: 210px;

    &.search {
      @include background-setup('../../../../../../image/empty-states/empty.png', center);
    }

    &.cart {
      @include background-setup('../../../../../../image/empty-states/empty-cart.svg', center);
    }

    &.auth {
      @include background-setup('../../../../../../image/empty-states/unauthorized.svg', center);
    }

    &.history {
      @include background-setup('../../../../../../image/empty-states/empty-history.svg', center);
    }

    &.user {
      @include background-setup('../../../../../../image/empty-states/empty-user.svg', center);
    }
  }

  h2 {
    font-size: 18px;
    font-family: $font-light;
    color: $text-blue;
  }
}