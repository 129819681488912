@import '../../../../../css/styles/configs';

.mp-specials-banner {
  align-self: stretch;
  background: linear-gradient(90deg, #163f6b 0%, #1d68b7 100%);
  padding: 7px;

  &-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, .4);
    padding: 10px;
  }

  & > div {
    width: 100%;
  }

  &-1 {
    @include background-setup ('../../../../../image/special-offer.png', center);
    background-size: contain;
    flex: 1;
    margin-top: 10px;
  }
  &-2 {
    height: 40%;
    display: flex;

    .explore-btn {
      margin: auto;
      border: 4px double rgba(255, 255, 255, 0.5);
      color: $white;
      text-transform: uppercase;
      padding: 5px 10px;
      font-size: 15px;
      border-radius: 3px;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        border: 4px double $white;
        box-shadow: 2px 2px 15px rgba(0, 0, 0, .3);
      }
    }
  }
}

@media (max-width: 600px) {
  .mp-specials-banner {
    height: 160px;

    &-wrap {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-1 {
      height: 100%;
      @include background-setup ('../../../../../image/special-offer-long.png', center);
      flex: unset;
      margin-top: 0;
    }

    &-2 {
      margin-top: 20px;
    }

    &-1, &-2 {
      width: 70%;
    }
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .mp-specials-banner {
    height: 150px;

    &-wrap {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &-1 {
      height: 100%;
      @include background-setup ('../../../../../image/special-offer-long.png', center);
      flex: unset;
      margin-top: 0;
    }

    &-1, &-2 {
      width: 40%;
    }
  }
}
