@import '../../../../../css/styles/configs';

.contact-page {
  &-company {
    margin-bottom: 30px;

    .company-name {
      display: block;
      font-family: $font-semibold;
      font-size: 25px;
      color: $text-blue;
    }
    .company-address {
      display: block;
      color: $text;
      font-size: 14px;
    }
  }

  &-main {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 50px;
    margin-bottom: 50px;

    &-details {
      .details-section {
        color: $text;

        &:not(:last-child):not(:first-child) {
          margin-bottom: 10px;
        }

        span {
          display: block;
          font-size: 14px;
        }

        &-items {
          padding: 10px;

          .item {
            &:before {
              font-family: 'gt';
              font-size: 13px;
              margin-right: 10px;
              color: #0088cc;
              margin-top: 1px;
            }

            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }

          div.item {
            display: flex;
            align-items: flex-start;

            span:first-child {
              margin-right: 10px;              
            }
            a, a:visited {
              color: $text-blue;
            }
            a:hover {
              color: $text-darkblue;
            }
          }
        }

        &.general {
          margin-bottom: 30px;

          span {
            display: block;

            &.company-name {
              font-size: 25px;
              color: $text-blue;
            }
          }
        }

        &.time {
          .item:before {
            content: '\e92a';
          }
        }
        &.phone {
          .item:before {
            content: '\e927';
          }
        }
        &.mobile {
          .item:before {
            content: '\e928';
          }
        }
        &.mail {
          .item:before {
            content: '\e929';
          }
        }
        &.location {
          .item:before {
            content: '\e92b';
          }
          a {
            font-family: $font-bold;
          }
        }
      }
    }

    &-form {
      .form-name {
        font-size: 20px;
        margin-bottom: 20px;
        display: block;
      }
    }
  }

  .google-link {
    height: 70px;
    margin-top: 10px;
    background: rgb(36,36,36);
    background: linear-gradient(325deg, rgba(36,36,36,1) 35%, rgba(75,75,75,1) 100%);
  }
}